
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexia_BwP7WmXMqe_c4z0si2Z2BUQ1GSn_45vouFs1gAAAeoMeta } from "/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/pages/index.vue?macro=true";
import { default as indexPcSVgen6ettlVZQBTdAJNek1o83NfAmTEeHCTJXETJMMeta } from "/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/pages/[[lang]]/index.vue?macro=true";
import { default as startQMixED1QGQJyKQacmLuVwISQaQ0LiEy8Z6s_45ODSL_45sYMeta } from "/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/pages/[[lang]]/voucher/start.vue?macro=true";
import { default as start_45fsxRQf4DsChzwXbpTyI507bEap8foCuUQQ_45NAYHXLUMeta } from "/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/pages/[[lang]]/purchase/start.vue?macro=true";
import { default as checkoutXBjYWZHlGl1wpNZZpJoWF7lU8gktSJM8xqyyvSwx4fwMeta } from "/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/pages/[[lang]]/service-booking/checkout.vue?macro=true";
import { default as koVFJzCKl_45XdLmf6oOdFXRXtk_mvaiaX76AM_5Hkdp0I0Meta } from "/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/ko.vue?macro=true";
import { default as confirmationRTTK3qrPIZCymXls2_Oyjap7y6uE7wpeFlDCEzXzM6gMeta } from "/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/confirmation.vue?macro=true";
import { default as confirmationDXRhtn2rlrsLUlWF_45v02QUcoLz7Ju0_JRCqKV9hlfEcMeta } from "/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/confirmation.vue?macro=true";
import { default as buyer1vGgL_hhbMbqlWAvm173UnN7kSyUZLCxAJFgGkcWs_45IMeta } from "/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/buyer.vue?macro=true";
import { default as buyerivYNFHVTCHHFpTC2X6OSN8vZQnGSgLh_UQN3vUWl6kIMeta } from "/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/buyer.vue?macro=true";
import { default as payment2riOx9LZYeSTEdFGHQ3qQf2sZTl46F19e_45Zc0J92LpEMeta } from "/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/payment.vue?macro=true";
import { default as challengeG4PqhthY1nS_45gBrJ6PR6AfBmQTvfI2WbpBLST6j3HW8Meta } from "/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/redsys/challenge.vue?macro=true";
import { default as payment0mPU7rzrJxqEfIg2b5q4W9qRkuvLvw3dz2K32pjZjNIMeta } from "/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/payment.vue?macro=true";
import { default as PromotionHome_46pagewPPPwYICEU0RYWmJTCZH4ohQ2oseK1OpUkmwQl5SG8YMeta } from "/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/PromotionHome.page.vue?macro=true";
import { default as Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta } from "/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue?macro=true";
export default [
  {
    name: indexia_BwP7WmXMqe_c4z0si2Z2BUQ1GSn_45vouFs1gAAAeoMeta?.name ?? "index",
    path: indexia_BwP7WmXMqe_c4z0si2Z2BUQ1GSn_45vouFs1gAAAeoMeta?.path ?? "/",
    props: indexia_BwP7WmXMqe_c4z0si2Z2BUQ1GSn_45vouFs1gAAAeoMeta?.props ?? false,
    meta: indexia_BwP7WmXMqe_c4z0si2Z2BUQ1GSn_45vouFs1gAAAeoMeta || {},
    alias: indexia_BwP7WmXMqe_c4z0si2Z2BUQ1GSn_45vouFs1gAAAeoMeta?.alias || [],
    redirect: indexia_BwP7WmXMqe_c4z0si2Z2BUQ1GSn_45vouFs1gAAAeoMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/pages/index.vue")
  },
  {
    name: indexPcSVgen6ettlVZQBTdAJNek1o83NfAmTEeHCTJXETJMMeta?.name ?? "lang",
    path: indexPcSVgen6ettlVZQBTdAJNek1o83NfAmTEeHCTJXETJMMeta?.path ?? "/:lang?",
    props: indexPcSVgen6ettlVZQBTdAJNek1o83NfAmTEeHCTJXETJMMeta?.props ?? false,
    meta: indexPcSVgen6ettlVZQBTdAJNek1o83NfAmTEeHCTJXETJMMeta || {},
    alias: indexPcSVgen6ettlVZQBTdAJNek1o83NfAmTEeHCTJXETJMMeta?.alias || [],
    redirect: indexPcSVgen6ettlVZQBTdAJNek1o83NfAmTEeHCTJXETJMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/pages/[[lang]]/index.vue")
  },
  {
    name: startQMixED1QGQJyKQacmLuVwISQaQ0LiEy8Z6s_45ODSL_45sYMeta?.name ?? "lang-voucher-start",
    path: startQMixED1QGQJyKQacmLuVwISQaQ0LiEy8Z6s_45ODSL_45sYMeta?.path ?? "/:lang?/voucher/start",
    props: startQMixED1QGQJyKQacmLuVwISQaQ0LiEy8Z6s_45ODSL_45sYMeta?.props ?? false,
    meta: startQMixED1QGQJyKQacmLuVwISQaQ0LiEy8Z6s_45ODSL_45sYMeta || {},
    alias: startQMixED1QGQJyKQacmLuVwISQaQ0LiEy8Z6s_45ODSL_45sYMeta?.alias || [],
    redirect: startQMixED1QGQJyKQacmLuVwISQaQ0LiEy8Z6s_45ODSL_45sYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/pages/[[lang]]/voucher/start.vue")
  },
  {
    name: start_45fsxRQf4DsChzwXbpTyI507bEap8foCuUQQ_45NAYHXLUMeta?.name ?? "lang-purchase-start",
    path: start_45fsxRQf4DsChzwXbpTyI507bEap8foCuUQQ_45NAYHXLUMeta?.path ?? "/:lang?/purchase/start",
    props: start_45fsxRQf4DsChzwXbpTyI507bEap8foCuUQQ_45NAYHXLUMeta?.props ?? false,
    meta: start_45fsxRQf4DsChzwXbpTyI507bEap8foCuUQQ_45NAYHXLUMeta || {},
    alias: start_45fsxRQf4DsChzwXbpTyI507bEap8foCuUQQ_45NAYHXLUMeta?.alias || [],
    redirect: start_45fsxRQf4DsChzwXbpTyI507bEap8foCuUQQ_45NAYHXLUMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/pages/[[lang]]/purchase/start.vue")
  },
  {
    name: checkoutXBjYWZHlGl1wpNZZpJoWF7lU8gktSJM8xqyyvSwx4fwMeta?.name ?? "lang-service-booking-checkout",
    path: checkoutXBjYWZHlGl1wpNZZpJoWF7lU8gktSJM8xqyyvSwx4fwMeta?.path ?? "/:lang?/service-booking/checkout",
    props: checkoutXBjYWZHlGl1wpNZZpJoWF7lU8gktSJM8xqyyvSwx4fwMeta?.props ?? false,
    meta: checkoutXBjYWZHlGl1wpNZZpJoWF7lU8gktSJM8xqyyvSwx4fwMeta || {},
    alias: checkoutXBjYWZHlGl1wpNZZpJoWF7lU8gktSJM8xqyyvSwx4fwMeta?.alias || [],
    redirect: checkoutXBjYWZHlGl1wpNZZpJoWF7lU8gktSJM8xqyyvSwx4fwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/pages/[[lang]]/service-booking/checkout.vue")
  },
  {
    name: koVFJzCKl_45XdLmf6oOdFXRXtk_mvaiaX76AM_5Hkdp0I0Meta?.name ?? "lang-purchase-packagepurchaseuuid-ko",
    path: koVFJzCKl_45XdLmf6oOdFXRXtk_mvaiaX76AM_5Hkdp0I0Meta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/ko",
    props: koVFJzCKl_45XdLmf6oOdFXRXtk_mvaiaX76AM_5Hkdp0I0Meta?.props ?? false,
    meta: koVFJzCKl_45XdLmf6oOdFXRXtk_mvaiaX76AM_5Hkdp0I0Meta || {},
    alias: koVFJzCKl_45XdLmf6oOdFXRXtk_mvaiaX76AM_5Hkdp0I0Meta?.alias || [],
    redirect: koVFJzCKl_45XdLmf6oOdFXRXtk_mvaiaX76AM_5Hkdp0I0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/ko.vue")
  },
  {
    name: confirmationRTTK3qrPIZCymXls2_Oyjap7y6uE7wpeFlDCEzXzM6gMeta?.name ?? "lang-voucher-voucherpurchaseuuid-confirmation",
    path: confirmationRTTK3qrPIZCymXls2_Oyjap7y6uE7wpeFlDCEzXzM6gMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/confirmation",
    props: confirmationRTTK3qrPIZCymXls2_Oyjap7y6uE7wpeFlDCEzXzM6gMeta?.props ?? false,
    meta: confirmationRTTK3qrPIZCymXls2_Oyjap7y6uE7wpeFlDCEzXzM6gMeta || {},
    alias: confirmationRTTK3qrPIZCymXls2_Oyjap7y6uE7wpeFlDCEzXzM6gMeta?.alias || [],
    redirect: confirmationRTTK3qrPIZCymXls2_Oyjap7y6uE7wpeFlDCEzXzM6gMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/confirmation.vue")
  },
  {
    name: confirmationDXRhtn2rlrsLUlWF_45v02QUcoLz7Ju0_JRCqKV9hlfEcMeta?.name ?? "lang-purchase-packagepurchaseuuid-confirmation",
    path: confirmationDXRhtn2rlrsLUlWF_45v02QUcoLz7Ju0_JRCqKV9hlfEcMeta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/confirmation",
    props: confirmationDXRhtn2rlrsLUlWF_45v02QUcoLz7Ju0_JRCqKV9hlfEcMeta?.props ?? false,
    meta: confirmationDXRhtn2rlrsLUlWF_45v02QUcoLz7Ju0_JRCqKV9hlfEcMeta || {},
    alias: confirmationDXRhtn2rlrsLUlWF_45v02QUcoLz7Ju0_JRCqKV9hlfEcMeta?.alias || [],
    redirect: confirmationDXRhtn2rlrsLUlWF_45v02QUcoLz7Ju0_JRCqKV9hlfEcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/confirmation.vue")
  },
  {
    name: buyer1vGgL_hhbMbqlWAvm173UnN7kSyUZLCxAJFgGkcWs_45IMeta?.name ?? "lang-voucher-voucherpurchaseuuid-checkout-buyer",
    path: buyer1vGgL_hhbMbqlWAvm173UnN7kSyUZLCxAJFgGkcWs_45IMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/checkout/buyer",
    props: buyer1vGgL_hhbMbqlWAvm173UnN7kSyUZLCxAJFgGkcWs_45IMeta?.props ?? false,
    meta: buyer1vGgL_hhbMbqlWAvm173UnN7kSyUZLCxAJFgGkcWs_45IMeta || {},
    alias: buyer1vGgL_hhbMbqlWAvm173UnN7kSyUZLCxAJFgGkcWs_45IMeta?.alias || [],
    redirect: buyer1vGgL_hhbMbqlWAvm173UnN7kSyUZLCxAJFgGkcWs_45IMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/buyer.vue")
  },
  {
    name: buyerivYNFHVTCHHFpTC2X6OSN8vZQnGSgLh_UQN3vUWl6kIMeta?.name ?? "lang-purchase-packagepurchaseuuid-checkout-buyer",
    path: buyerivYNFHVTCHHFpTC2X6OSN8vZQnGSgLh_UQN3vUWl6kIMeta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/checkout/buyer",
    props: buyerivYNFHVTCHHFpTC2X6OSN8vZQnGSgLh_UQN3vUWl6kIMeta?.props ?? false,
    meta: buyerivYNFHVTCHHFpTC2X6OSN8vZQnGSgLh_UQN3vUWl6kIMeta || {},
    alias: buyerivYNFHVTCHHFpTC2X6OSN8vZQnGSgLh_UQN3vUWl6kIMeta?.alias || [],
    redirect: buyerivYNFHVTCHHFpTC2X6OSN8vZQnGSgLh_UQN3vUWl6kIMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/buyer.vue")
  },
  {
    name: payment2riOx9LZYeSTEdFGHQ3qQf2sZTl46F19e_45Zc0J92LpEMeta?.name ?? "lang-voucher-voucherpurchaseuuid-checkout-payment",
    path: payment2riOx9LZYeSTEdFGHQ3qQf2sZTl46F19e_45Zc0J92LpEMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/checkout/payment",
    props: payment2riOx9LZYeSTEdFGHQ3qQf2sZTl46F19e_45Zc0J92LpEMeta?.props ?? false,
    meta: payment2riOx9LZYeSTEdFGHQ3qQf2sZTl46F19e_45Zc0J92LpEMeta || {},
    alias: payment2riOx9LZYeSTEdFGHQ3qQf2sZTl46F19e_45Zc0J92LpEMeta?.alias || [],
    redirect: payment2riOx9LZYeSTEdFGHQ3qQf2sZTl46F19e_45Zc0J92LpEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/payment.vue")
  },
  {
    name: challengeG4PqhthY1nS_45gBrJ6PR6AfBmQTvfI2WbpBLST6j3HW8Meta?.name ?? "lang-voucher-voucherpurchaseuuid-redsys-challenge",
    path: challengeG4PqhthY1nS_45gBrJ6PR6AfBmQTvfI2WbpBLST6j3HW8Meta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/redsys/challenge",
    props: challengeG4PqhthY1nS_45gBrJ6PR6AfBmQTvfI2WbpBLST6j3HW8Meta?.props ?? false,
    meta: challengeG4PqhthY1nS_45gBrJ6PR6AfBmQTvfI2WbpBLST6j3HW8Meta || {},
    alias: challengeG4PqhthY1nS_45gBrJ6PR6AfBmQTvfI2WbpBLST6j3HW8Meta?.alias || [],
    redirect: challengeG4PqhthY1nS_45gBrJ6PR6AfBmQTvfI2WbpBLST6j3HW8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/redsys/challenge.vue")
  },
  {
    name: payment0mPU7rzrJxqEfIg2b5q4W9qRkuvLvw3dz2K32pjZjNIMeta?.name ?? "lang-purchase-packagepurchaseuuid-checkout-payment",
    path: payment0mPU7rzrJxqEfIg2b5q4W9qRkuvLvw3dz2K32pjZjNIMeta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/checkout/payment",
    props: payment0mPU7rzrJxqEfIg2b5q4W9qRkuvLvw3dz2K32pjZjNIMeta?.props ?? false,
    meta: payment0mPU7rzrJxqEfIg2b5q4W9qRkuvLvw3dz2K32pjZjNIMeta || {},
    alias: payment0mPU7rzrJxqEfIg2b5q4W9qRkuvLvw3dz2K32pjZjNIMeta?.alias || [],
    redirect: payment0mPU7rzrJxqEfIg2b5q4W9qRkuvLvw3dz2K32pjZjNIMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/payment.vue")
  },
  {
    name: PromotionHome_46pagewPPPwYICEU0RYWmJTCZH4ohQ2oseK1OpUkmwQl5SG8YMeta?.name ?? "Promotion home",
    path: PromotionHome_46pagewPPPwYICEU0RYWmJTCZH4ohQ2oseK1OpUkmwQl5SG8YMeta?.path ?? "/pt/promocoes/",
    props: PromotionHome_46pagewPPPwYICEU0RYWmJTCZH4ohQ2oseK1OpUkmwQl5SG8YMeta?.props ?? false,
    meta: PromotionHome_46pagewPPPwYICEU0RYWmJTCZH4ohQ2oseK1OpUkmwQl5SG8YMeta || {},
    alias: PromotionHome_46pagewPPPwYICEU0RYWmJTCZH4ohQ2oseK1OpUkmwQl5SG8YMeta?.alias || [],
    redirect: PromotionHome_46pagewPPPwYICEU0RYWmJTCZH4ohQ2oseK1OpUkmwQl5SG8YMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/PromotionHome.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 0",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/black-friday/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/black-friday/alicante/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/black-friday/alicante/alicante/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 3",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/black-friday/andalucia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 4",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/black-friday/barcelona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 5",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/black-friday/barcelona/barcelona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 6",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/black-friday/barcelona/la-garriga/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 7",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/black-friday/barcelona/sitges/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 8",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/black-friday/burgos/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 9",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/black-friday/burgos/aranda-de-duero/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 10",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/black-friday/caceres/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 11",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/black-friday/caceres/caceres/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 12",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/black-friday/cadiz/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 13",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/black-friday/cadiz/cadiz/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 14",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/black-friday/cadiz/tarifa/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 15",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/black-friday/canarias/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 16",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/black-friday/castilla-la-mancha/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 17",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/black-friday/castilla-y-leon/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 18",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/black-friday/cataluna/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 19",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/black-friday/comunidad-de-madrid/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 20",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/black-friday/comunidad-valenciana/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 21",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/black-friday/cordoba/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 22",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/black-friday/cordoba/cordoba/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 23",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/black-friday/cordoba/lucena/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 24",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/black-friday/extremadura/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 25",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/black-friday/fuerteventura/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 26",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/black-friday/fuerteventura/pajara/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 27",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/black-friday/gran-canaria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 28",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/black-friday/gran-canaria/maspalomas/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 29",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/black-friday/gran-canaria/mogan/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 30",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/black-friday/gran-canaria/san-bartolome-de-tirajana/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 31",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/black-friday/granada/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 32",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/black-friday/granada/granada/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 33",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/black-friday/huelva/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 34",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/black-friday/huelva/isla-cristina/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 35",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/black-friday/islas-baleares/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 36",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/black-friday/lanzarote/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 37",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/black-friday/lanzarote/yaiza/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 38",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/black-friday/madrid/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 39",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/black-friday/madrid/madrid/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 40",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/black-friday/malaga/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 41",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/black-friday/malaga/fuengirola/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 42",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/black-friday/malaga/mijas/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 43",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/black-friday/mallorca/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 44",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/black-friday/mallorca/calvia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 45",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/black-friday/sevilla/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 46",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/black-friday/sevilla/sevilla/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 47",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/black-friday/tarragona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 48",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/black-friday/tarragona/montbrio-del-camp/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 49",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/black-friday/tarragona/salou/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 50",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/black-friday/tenerife/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 51",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/black-friday/tenerife/adeje/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 52",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/black-friday/tenerife/arona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 53",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/black-friday/tenerife/guia-de-isora/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 54",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/black-friday/tenerife/santa-cruz-de-tenerife/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 55",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/black-friday/tenerife/santa-ursula/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 56",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/black-friday/toledo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 57",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/black-friday/toledo/toledo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 58",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/black-friday/valencia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 59",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/black-friday/valencia/valencia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 60",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 61",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/alava/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 62",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/alava/laguardia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 63",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/alava/vitoria-gasteiz/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 64",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/almeria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 65",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/almeria/alhama-de-almeria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 66",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/andalucia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 67",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/aragon/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 68",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/badajoz/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 69",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/badajoz/merida/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 70",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/barcelona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 71",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/barcelona/barcelona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 72",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/barcelona/la-garriga/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 73",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/barcelona/sant-boi-de-llobregat/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 74",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/barcelona/sant-joan-despi/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 75",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/barcelona/sitges/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 76",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/burgos/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 77",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/burgos/aranda-de-duero/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 78",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/caceres/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 79",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/caceres/caceres/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 80",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/cadiz/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 81",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/cadiz/grazalema/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 82",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/cadiz/zahara/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 83",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/canarias/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 84",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/castellon/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 85",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/castellon/la-vilavella/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 86",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/castilla-la-mancha/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 87",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/castilla-y-leon/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 88",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/cataluna/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 89",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/ciudad-real/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 90",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/ciudad-real/almagro/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 91",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/comunidad-de-madrid/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 92",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/comunidad-valenciana/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 93",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/cordoba/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 94",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/cordoba/lucena/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 95",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/extremadura/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 96",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/galicia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 97",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/gran-canaria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 98",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/gran-canaria/maspalomas/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 99",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/gran-canaria/mogan/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 100",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/gran-canaria/san-bartolome-de-tirajana/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 101",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/granada/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 102",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/granada/almunecar/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 103",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/granada/alpujarra-de-la-sierra/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 104",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/granada/granada/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 105",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/guadalajara/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 106",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/guadalajara/abanades/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 107",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/guipuzcoa/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 108",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/guipuzcoa/orio/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 109",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/huelva/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 110",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/huelva/cartaya/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 111",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/huelva/punta-umbria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 112",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/huesca/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 113",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/huesca/sallent-de-gallego/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 114",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/islas-baleares/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 115",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/lanzarote/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 116",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/lanzarote/yaiza/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 117",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/lugo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 118",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/lugo/burela/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 119",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/madrid/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 120",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/madrid/madrid/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 121",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/malaga/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 122",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/malaga/fuengirola/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 123",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/mallorca/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 124",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/mallorca/calvia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 125",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/mallorca/palma/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 126",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/menorca/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 127",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/menorca/ciutadella-de-menorca/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 128",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/murcia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 129",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/murcia/archena/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 130",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/pais-vasco/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 131",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/pontevedra/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 132",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/pontevedra/vigo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 133",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/region-de-murcia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 134",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/salamanca/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 135",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/salamanca/bermellar/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 136",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/segovia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 137",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/segovia/segovia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 138",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/sevilla/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 139",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/sevilla/sevilla/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 140",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/sevilla/umbrete/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 141",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/tarragona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 142",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/tarragona/el-perello/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 143",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/tarragona/salou/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 144",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/tenerife/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 145",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/tenerife/adeje/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 146",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/tenerife/arona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 147",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/tenerife/guia-de-isora/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 148",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/tenerife/playa-de-las-americas/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 149",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/tenerife/san-cristobal-de-la-laguna/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 150",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/toledo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 151",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/toledo/carranque/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 152",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-natal/toledo/toledo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 153",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-estadia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 154",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-estadia/andalucia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 155",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-estadia/asturias/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 156",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-estadia/asturias/parres/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 157",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-estadia/barcelona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 158",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-estadia/barcelona/berga/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 159",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-estadia/barcelona/la-garriga/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 160",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-estadia/cadiz/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 161",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-estadia/cadiz/grazalema/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 162",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-estadia/castilla-y-leon/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 163",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-estadia/cataluna/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 164",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-estadia/distrito-de-lisboa/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 165",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-estadia/distrito-de-oporto/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 166",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-estadia/distrito-de-viana-do-castelo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 167",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-estadia/distrito-de-vila-real/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 168",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-estadia/granada/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 169",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-estadia/granada/niguelas/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 170",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-estadia/guipuzcoa/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 171",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-estadia/guipuzcoa/orio/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 172",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-estadia/lisboa/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 173",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-estadia/lisboa/ericeira/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 174",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-estadia/oporto/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 175",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-estadia/oporto/vila-nova-de-gaia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 176",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-estadia/pais-vasco/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 177",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-estadia/principado-de-asturias/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 178",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-estadia/salamanca/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 179",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-estadia/salamanca/bermellar/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 180",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-estadia/viana-do-castelo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 181",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-estadia/viana-do-castelo/viana-do-castelo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 182",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-estadia/vila-real/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 183",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-estadia/vila-real/boticas/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 184",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 185",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/a-coruna/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 186",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/a-coruna/carnota/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 187",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/a-coruna/ferrol/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 188",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/a-coruna/outes/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 189",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/acores/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 190",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/acores/ponta-delgada/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 191",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/alava/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 192",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/alava/laguardia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 193",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/alava/vitoria-gasteiz/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 194",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/albacete/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 195",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/albacete/albacete/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 196",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/alicante/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 197",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/alicante/alicante/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 198",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/alicante/altea/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 199",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/alicante/benidorm/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 200",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/alicante/calpe/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 201",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/alicante/denia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 202",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/alicante/l-alfas-del-pi/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 203",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/alicante/rojales/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 204",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/almeria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 205",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/almeria/almeria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 206",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/andalucia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 207",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/aragon/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 208",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/asturias/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 209",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/asturias/langreo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 210",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/asturias/parres/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 211",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/badajoz/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 212",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/badajoz/merida/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 213",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/barcelona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 214",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/barcelona/barcelona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 215",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/barcelona/berga/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 216",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/barcelona/caldes-de-montbui/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 217",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/barcelona/calella/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 218",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/barcelona/sant-boi-de-llobregat/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 219",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/barcelona/sant-joan-despi/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 220",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/barcelona/seva/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 221",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/barcelona/sitges/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 222",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/barcelona/vallromanes/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 223",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/burgos/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 224",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/burgos/aranda-de-duero/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 225",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/caceres/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 226",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/caceres/caceres/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 227",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/cadiz/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 228",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/cadiz/cadiz/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 229",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/cadiz/chiclana-de-la-frontera/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 230",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/cadiz/grazalema/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 231",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/cadiz/novo-sancti-petri/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 232",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/cadiz/tarifa/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 233",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/cadiz/vejer-de-la-frontera/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 234",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/cadiz/zahara/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 235",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/canarias/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 236",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/cantabria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 237",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/cantabria/torrelavega/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 238",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/castellon/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 239",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/castellon/peniscola/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 240",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/castilla-la-mancha/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 241",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/castilla-y-leon/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 242",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/cataluna/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 243",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/ciudad-real/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 244",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/ciudad-real/almagro/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 245",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/comunidad-autonoma-de-cantabria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 246",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/comunidad-de-madrid/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 247",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/comunidad-valenciana/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 248",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/cordoba/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 249",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/cordoba/cordoba/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 250",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/cordoba/lucena/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 251",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/distrito-de-evora/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 252",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/distrito-de-faro/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 253",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/distrito-de-leiria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 254",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/distrito-de-lisboa/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 255",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/distrito-de-oporto/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 256",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/distrito-de-viana-do-castelo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 257",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/distrito-de-vila-real/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 258",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/escaldes-engordany/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 259",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/escaldes-engordany/les-escaldes/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 260",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/evora/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 261",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/evora/evora/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 262",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/extremadura/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 263",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/faro/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 264",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/faro/albufeira/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 265",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/faro/monchique/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 266",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/faro/portimao/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 267",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/fuerteventura/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 268",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/fuerteventura/antigua/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 269",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/galicia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 270",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/girona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 271",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/girona/caldes-de-malavella/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 272",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/girona/castello-dempuries/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 273",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/girona/llanars/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 274",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/girona/torrent/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 275",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/girona/toses/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 276",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/gran-canaria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 277",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/gran-canaria/agaete/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 278",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/gran-canaria/las-palmas-de-gran-canaria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 279",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/gran-canaria/maspalomas/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 280",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/gran-canaria/mogan/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 281",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/gran-canaria/san-agustin-maspalomas/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 282",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/gran-canaria/san-bartolome-de-tirajana/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 283",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/granada/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 284",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/granada/granada/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 285",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/granada/monachil/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 286",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/granada/motril/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 287",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/guadalajara/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 288",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/guadalajara/abanades/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 289",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/guipuzcoa/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 290",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/guipuzcoa/hondarribia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 291",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/guipuzcoa/orio/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 292",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/huelva/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 293",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/huelva/isla-cristina/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 294",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/huelva/punta-umbria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 295",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/huesca/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 296",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/huesca/huesca/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 297",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/huesca/sallent-de-gallego/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 298",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/islas-baleares/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 299",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/jaen/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 300",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/jaen/ubeda/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 301",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/lanzarote/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 302",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/lanzarote/puerto-del-carmen/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 303",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/lanzarote/teguise/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 304",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/lanzarote/yaiza/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 305",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/leiria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 306",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/leiria/alcobaca/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 307",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/leiria/monte-real/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 308",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/leiria/vestiaria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 309",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/leon/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 310",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/leon/astorga/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 311",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/lisboa/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 312",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/lisboa/ericeira/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 313",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/lisboa/lisboa/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 314",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/lisboa/torres-vedras/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 315",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/lleida/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 316",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/lleida/caldes-de-boi/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 317",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/lleida/vallbona-de-les-monges/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 318",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/lugo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 319",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/lugo/guitiriz/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 320",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/lugo/panton/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 321",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/lugo/vilalba/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 322",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/madeira/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 323",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/madeira/funchal/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 324",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/madrid/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 325",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/madrid/madrid/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 326",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/malaga/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 327",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/malaga/benalmadena/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 328",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/malaga/estepona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 329",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/malaga/fuengirola/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 330",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/malaga/malaga/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 331",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/malaga/marbella/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 332",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/malaga/torremolinos/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 333",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/mallorca/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 334",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/mallorca/cala-millor/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 335",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/mallorca/calvia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 336",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/mallorca/colonia-sant-jordi/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 337",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/mallorca/felanitx/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 338",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/mallorca/llucmajor/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 339",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/mallorca/montuiri/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 340",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/mallorca/muro/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 341",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/mallorca/palma/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 342",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/mallorca/sant-llorenc-des-cardassar/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 343",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/mallorca/soller/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 344",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/murcia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 345",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/murcia/murcia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 346",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/oporto/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 347",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/oporto/sao-felix-da-marinha/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 348",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/oporto/vila-do-conde/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 349",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/oporto/vila-nova-de-gaia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 350",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/pais-vasco/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 351",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/paroquia-de-escaldes-engordany/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 352",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/pontevedra/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 353",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/pontevedra/moana/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 354",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/pontevedra/pontevedra/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 355",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/pontevedra/vigo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 356",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/principado-de-asturias/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 357",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/regiao-de-acores/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 358",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/regiao-de-madeira/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 359",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/region-de-murcia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 360",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/segovia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 361",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/segovia/santo-tome-del-puerto/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 362",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/segovia/segovia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 363",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/sevilla/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 364",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/sevilla/sevilla/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 365",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/sevilla/umbrete/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 366",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/tarragona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 367",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/tarragona/el-perello/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 368",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/tarragona/montbrio-del-camp/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 369",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/tarragona/salou/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 370",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/tarragona/torroja-del-priorat/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 371",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/tenerife/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 372",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/tenerife/adeje/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 373",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/tenerife/arona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 374",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/tenerife/playa-de-las-americas/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 375",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/tenerife/puerto-de-la-cruz/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 376",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/tenerife/san-cristobal-de-la-laguna/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 377",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/tenerife/santa-cruz-de-tenerife/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 378",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/tenerife/santa-ursula/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 379",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/toledo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 380",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/toledo/carranque/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 381",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/toledo/toledo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 382",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/valencia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 383",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/valencia/gandia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 384",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/valencia/paterna/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 385",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/valencia/valencia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 386",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/valladolid/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 387",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/valladolid/penafiel/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 388",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/viana-do-castelo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 389",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/viana-do-castelo/arcos-de-valdevez/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 390",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/viana-do-castelo/viana-do-castelo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 391",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/vila-real/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 392",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/vila-real/boticas/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 393",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-de-spa-e-massagem-de-relaxamento/vila-real/ribeira-de-pena/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 394",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-massagem-de-natal/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 395",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-massagem-de-natal/alava/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 396",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-massagem-de-natal/alava/laguardia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 397",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-massagem-de-natal/alava/vitoria-gasteiz/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 398",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-massagem-de-natal/almeria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 399",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-massagem-de-natal/almeria/almeria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 400",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-massagem-de-natal/andalucia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 401",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-massagem-de-natal/aragon/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 402",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-massagem-de-natal/badajoz/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 403",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-massagem-de-natal/badajoz/merida/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 404",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-massagem-de-natal/barcelona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 405",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-massagem-de-natal/barcelona/barcelona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 406",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-massagem-de-natal/barcelona/berga/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 407",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-massagem-de-natal/barcelona/caldes-de-montbui/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 408",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-massagem-de-natal/barcelona/sant-joan-despi/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 409",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-massagem-de-natal/cadiz/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 410",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-massagem-de-natal/cadiz/tarifa/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 411",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-massagem-de-natal/cadiz/zahara/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 412",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-massagem-de-natal/canarias/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 413",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-massagem-de-natal/castilla-la-mancha/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 414",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-massagem-de-natal/castilla-y-leon/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 415",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-massagem-de-natal/cataluna/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 416",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-massagem-de-natal/ciudad-real/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 417",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-massagem-de-natal/ciudad-real/almagro/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 418",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-massagem-de-natal/comunidad-de-madrid/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 419",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-massagem-de-natal/cordoba/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 420",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-massagem-de-natal/cordoba/cordoba/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 421",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-massagem-de-natal/extremadura/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 422",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-massagem-de-natal/galicia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 423",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-massagem-de-natal/girona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 424",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-massagem-de-natal/girona/torrent/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 425",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-massagem-de-natal/gran-canaria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 426",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-massagem-de-natal/gran-canaria/san-agustin-maspalomas/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 427",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-massagem-de-natal/gran-canaria/san-bartolome-de-tirajana/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 428",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-massagem-de-natal/granada/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 429",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-massagem-de-natal/granada/granada/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 430",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-massagem-de-natal/guadalajara/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 431",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-massagem-de-natal/guadalajara/abanades/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 432",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-massagem-de-natal/huelva/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 433",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-massagem-de-natal/huelva/punta-umbria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 434",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-massagem-de-natal/huesca/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 435",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-massagem-de-natal/huesca/sallent-de-gallego/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 436",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-massagem-de-natal/islas-baleares/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 437",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-massagem-de-natal/lanzarote/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 438",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-massagem-de-natal/lanzarote/puerto-del-carmen/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 439",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-massagem-de-natal/lanzarote/teguise/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 440",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-massagem-de-natal/madrid/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 441",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-massagem-de-natal/madrid/madrid/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 442",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-massagem-de-natal/mallorca/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 443",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-massagem-de-natal/mallorca/montuiri/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 444",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-massagem-de-natal/mallorca/palma/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 445",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-massagem-de-natal/pais-vasco/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 446",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-massagem-de-natal/pontevedra/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 447",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-massagem-de-natal/pontevedra/pontevedra/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 448",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-massagem-de-natal/pontevedra/vigo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 449",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-massagem-de-natal/segovia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 450",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-massagem-de-natal/segovia/segovia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 451",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-massagem-de-natal/sevilla/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 452",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-massagem-de-natal/sevilla/sevilla/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 453",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-massagem-de-natal/sevilla/umbrete/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 454",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-massagem-de-natal/tarragona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 455",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-massagem-de-natal/tarragona/el-perello/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 456",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-massagem-de-natal/tarragona/salou/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 457",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-massagem-de-natal/tenerife/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 458",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-massagem-de-natal/tenerife/adeje/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 459",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-massagem-de-natal/tenerife/arona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 460",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-massagem-de-natal/tenerife/playa-de-las-americas/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 461",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-massagem-de-natal/tenerife/san-cristobal-de-la-laguna/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 462",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-massagem-de-natal/toledo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 463",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-massagem-de-natal/toledo/carranque/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 464",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-massagem-de-natal/toledo/toledo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 465",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-de-natal/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 466",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-de-natal/alava/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 467",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-de-natal/alava/laguardia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 468",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-de-natal/andalucia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 469",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-de-natal/barcelona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 470",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-de-natal/barcelona/berga/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 471",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-de-natal/barcelona/sant-joan-despi/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 472",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-de-natal/canarias/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 473",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-de-natal/castilla-la-mancha/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 474",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-de-natal/castilla-y-leon/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 475",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-de-natal/cataluna/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 476",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-de-natal/granada/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 477",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-de-natal/granada/granada/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 478",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-de-natal/lanzarote/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 479",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-de-natal/lanzarote/yaiza/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 480",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-de-natal/pais-vasco/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 481",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-de-natal/segovia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 482",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-de-natal/segovia/segovia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 483",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-de-natal/toledo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 484",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-de-natal/toledo/toledo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 485",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 486",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/a-coruna/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 487",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/a-coruna/ferrol/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 488",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/a-coruna/outes/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 489",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/acores/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 490",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/acores/ponta-delgada/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 491",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/alava/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 492",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/alava/laguardia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 493",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/albacete/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 494",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/albacete/albacete/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 495",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/alicante/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 496",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/alicante/alicante/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 497",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/alicante/altea/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 498",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/alicante/benidorm/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 499",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/alicante/calpe/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 500",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/alicante/denia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 501",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/alicante/l-alfas-del-pi/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 502",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/alicante/rojales/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 503",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/almeria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 504",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/almeria/almeria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 505",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/almeria/roquetas-de-mar/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 506",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/andalucia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 507",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/asturias/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 508",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/asturias/parres/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 509",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/barcelona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 510",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/barcelona/barcelona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 511",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/barcelona/berga/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 512",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/barcelona/calella/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 513",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/barcelona/sant-boi-de-llobregat/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 514",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/barcelona/sant-joan-despi/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 515",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/barcelona/seva/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 516",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/cadiz/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 517",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/cadiz/cadiz/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 518",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/cadiz/tarifa/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 519",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/canarias/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 520",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/cantabria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 521",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/cantabria/torrelavega/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 522",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/castellon/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 523",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/castellon/peniscola/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 524",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/castilla-la-mancha/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 525",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/castilla-y-leon/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 526",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/cataluna/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 527",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/comunidad-autonoma-de-cantabria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 528",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/comunidad-de-madrid/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 529",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/comunidad-valenciana/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 530",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/cordoba/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 531",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/cordoba/lucena/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 532",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/distrito-de-evora/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 533",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/distrito-de-faro/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 534",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/distrito-de-leiria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 535",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/distrito-de-lisboa/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 536",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/distrito-de-oporto/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 537",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/distrito-de-viana-do-castelo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 538",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/distrito-de-vila-real/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 539",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/evora/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 540",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/evora/evora/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 541",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/faro/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 542",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/faro/albufeira/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 543",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/faro/monchique/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 544",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/faro/portimao/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 545",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/fuerteventura/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 546",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/fuerteventura/antigua/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 547",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/galicia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 548",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/girona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 549",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/girona/sant-feliu-de-guixols/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 550",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/girona/torrent/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 551",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/gran-canaria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 552",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/gran-canaria/las-palmas-de-gran-canaria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 553",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/gran-canaria/maspalomas/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 554",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/gran-canaria/mogan/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 555",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/gran-canaria/san-bartolome-de-tirajana/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 556",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/granada/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 557",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/granada/almunecar/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 558",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/granada/granada/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 559",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/granada/motril/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 560",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/guadalajara/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 561",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/guadalajara/abanades/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 562",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/guipuzcoa/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 563",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/guipuzcoa/hondarribia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 564",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/guipuzcoa/orio/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 565",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/huelva/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 566",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/huelva/ayamonte/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 567",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/huelva/cartaya/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 568",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/huelva/punta-umbria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 569",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/islas-baleares/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 570",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/lanzarote/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 571",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/lanzarote/puerto-del-carmen/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 572",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/lanzarote/teguise/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 573",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/lanzarote/yaiza/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 574",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/leiria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 575",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/leiria/alcobaca/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 576",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/leiria/monte-real/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 577",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/leon/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 578",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/leon/astorga/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 579",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/lisboa/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 580",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/lisboa/ericeira/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 581",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/lisboa/lisboa/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 582",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/lisboa/torres-vedras/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 583",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/lugo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 584",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/lugo/vilalba/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 585",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/madeira/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 586",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/madeira/funchal/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 587",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/madrid/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 588",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/madrid/madrid/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 589",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/malaga/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 590",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/malaga/estepona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 591",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/malaga/fuengirola/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 592",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/malaga/marbella/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 593",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/malaga/torremolinos/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 594",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/mallorca/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 595",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/mallorca/cala-millor/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 596",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/mallorca/calvia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 597",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/mallorca/colonia-sant-jordi/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 598",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/mallorca/llucmajor/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 599",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/mallorca/montuiri/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 600",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/mallorca/muro/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 601",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/mallorca/palma/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 602",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/mallorca/sant-llorenc-des-cardassar/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 603",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/mallorca/son-servera/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 604",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/murcia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 605",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/murcia/murcia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 606",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/oporto/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 607",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/oporto/sao-felix-da-marinha/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 608",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/pais-vasco/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 609",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/pontevedra/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 610",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/pontevedra/moana/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 611",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/pontevedra/pontevedra/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 612",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/pontevedra/vigo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 613",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/principado-de-asturias/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 614",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/regiao-de-acores/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 615",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/regiao-de-madeira/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 616",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/region-de-murcia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 617",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/segovia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 618",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/segovia/segovia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 619",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/sevilla/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 620",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/sevilla/sevilla/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 621",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/tenerife/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 622",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/tenerife/adeje/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 623",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/tenerife/santa-cruz-de-tenerife/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 624",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/tenerife/santa-ursula/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 625",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/valencia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 626",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/valencia/gandia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 627",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/valencia/valencia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 628",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/valladolid/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 629",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/valladolid/penafiel/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 630",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/viana-do-castelo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 631",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/viana-do-castelo/arcos-de-valdevez/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 632",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/viana-do-castelo/caminha/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 633",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/viana-do-castelo/viana-do-castelo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 634",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/vila-real/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 635",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/vila-real/boticas/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 636",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/vila-real/ribeira-de-pena/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 637",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/vizcaya/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 638",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-e-ritual-termal/vizcaya/urduna/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 639",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco-de-natal/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 640",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco-de-natal/andalucia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 641",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco-de-natal/badajoz/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 642",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco-de-natal/badajoz/merida/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 643",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco-de-natal/canarias/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 644",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco-de-natal/castilla-la-mancha/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 645",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco-de-natal/cordoba/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 646",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco-de-natal/cordoba/cordoba/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 647",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco-de-natal/extremadura/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 648",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco-de-natal/galicia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 649",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco-de-natal/gran-canaria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 650",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco-de-natal/gran-canaria/maspalomas/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 651",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco-de-natal/gran-canaria/mogan/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 652",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco-de-natal/gran-canaria/san-bartolome-de-tirajana/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 653",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco-de-natal/guadalajara/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 654",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco-de-natal/guadalajara/abanades/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 655",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco-de-natal/guipuzcoa/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 656",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco-de-natal/guipuzcoa/orio/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 657",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco-de-natal/huelva/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 658",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco-de-natal/huelva/cartaya/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 659",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco-de-natal/islas-baleares/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 660",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco-de-natal/lugo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 661",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco-de-natal/lugo/burela/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 662",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco-de-natal/mallorca/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 663",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco-de-natal/mallorca/calvia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 664",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco-de-natal/pais-vasco/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 665",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco-de-natal/tenerife/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 666",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco-de-natal/tenerife/adeje/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 667",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco-de-natal/tenerife/puerto-de-la-cruz/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 668",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco-de-natal/tenerife/santa-ursula/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 669",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco-de-natal/toledo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 670",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco-de-natal/toledo/toledo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 671",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 672",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/a-coruna/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 673",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/a-coruna/a-coruna/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 674",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/a-coruna/carnota/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 675",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/a-coruna/ferrol/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 676",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/alava/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 677",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/alava/laguardia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 678",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/albacete/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 679",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/albacete/albacete/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 680",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/alicante/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 681",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/alicante/alicante/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 682",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/alicante/benidorm/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 683",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/alicante/calpe/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 684",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/alicante/l-alfas-del-pi/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 685",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/alicante/rojales/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 686",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/alicante/teulada/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 687",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/almeria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 688",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/almeria/roquetas-de-mar/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 689",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/andalucia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 690",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/aragon/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 691",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/asturias/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 692",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/asturias/parres/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 693",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/badajoz/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 694",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/badajoz/merida/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 695",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/barcelona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 696",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/barcelona/barcelona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 697",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/barcelona/calella/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 698",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/barcelona/la-garriga/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 699",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/barcelona/sant-boi-de-llobregat/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 700",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/barcelona/sitges/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 701",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/caceres/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 702",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/caceres/caceres/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 703",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/cadiz/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 704",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/cadiz/chiclana-de-la-frontera/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 705",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/cadiz/grazalema/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 706",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/cadiz/novo-sancti-petri/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 707",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/cadiz/tarifa/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 708",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/canarias/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 709",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/cantabria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 710",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/cantabria/la-hermida/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 711",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/castellon/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 712",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/castellon/montanejos/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 713",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/castellon/peniscola/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 714",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/castilla-la-mancha/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 715",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/castilla-y-leon/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 716",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/cataluna/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 717",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/comunidad-autonoma-de-cantabria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 718",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/comunidad-de-madrid/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 719",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/comunidad-valenciana/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 720",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/cordoba/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 721",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/cordoba/cordoba/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 722",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/distrito-de-faro/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 723",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/distrito-de-lisboa/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 724",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/distrito-de-setubal/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 725",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/distrito-de-viana-do-castelo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 726",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/escaldes-engordany/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 727",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/escaldes-engordany/les-escaldes/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 728",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/extremadura/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 729",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/faro/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 730",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/faro/monchique/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 731",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/fuerteventura/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 732",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/fuerteventura/pajara/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 733",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/galicia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 734",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/girona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 735",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/girona/caldes-de-malavella/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 736",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/girona/castello-dempuries/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 737",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/girona/llanars/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 738",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/girona/lloret-de-mar/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 739",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/girona/toses/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 740",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/gran-canaria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 741",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/gran-canaria/agaete/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 742",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/gran-canaria/maspalomas/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 743",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/gran-canaria/mogan/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 744",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/gran-canaria/san-bartolome-de-tirajana/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 745",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/granada/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 746",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/granada/granada/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 747",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/guadalajara/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 748",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/guadalajara/abanades/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 749",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/guipuzcoa/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 750",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/guipuzcoa/orio/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 751",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/huelva/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 752",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/huelva/cartaya/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 753",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/huesca/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 754",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/huesca/monzon/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 755",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/islas-baleares/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 756",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/jaen/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 757",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/jaen/ubeda/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 758",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/lisboa/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 759",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/lisboa/ericeira/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 760",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/lisboa/lisboa/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 761",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/lleida/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 762",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/lleida/caldes-de-boi/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 763",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/lugo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 764",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/lugo/burela/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 765",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/lugo/vilalba/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 766",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/madrid/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 767",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/madrid/madrid/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 768",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/malaga/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 769",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/malaga/estepona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 770",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/malaga/malaga/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 771",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/malaga/marbella/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 772",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/mallorca/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 773",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/mallorca/cala-millor/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 774",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/mallorca/calvia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 775",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/mallorca/colonia-sant-jordi/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 776",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/mallorca/felanitx/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 777",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/mallorca/llucmajor/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 778",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/mallorca/muro/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 779",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/mallorca/palma/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 780",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/mallorca/sant-llorenc-des-cardassar/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 781",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/mallorca/soller/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 782",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/mallorca/son-servera/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 783",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/murcia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 784",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/murcia/archena/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 785",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/murcia/la-manga-del-mar-menor/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 786",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/pais-vasco/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 787",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/paroquia-de-escaldes-engordany/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 788",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/pontevedra/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 789",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/pontevedra/moana/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 790",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/pontevedra/pontevedra/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 791",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/pontevedra/tui/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 792",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/pontevedra/vigo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 793",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/principado-de-asturias/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 794",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/region-de-murcia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 795",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/setubal/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 796",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/setubal/santiago-do-cacem/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 797",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/sevilla/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 798",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/sevilla/sevilla/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 799",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/tenerife/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 800",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/tenerife/adeje/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 801",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/tenerife/arona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 802",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/tenerife/playa-de-las-americas/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 803",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/tenerife/puerto-de-la-cruz/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 804",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/tenerife/san-cristobal-de-la-laguna/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 805",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/tenerife/santa-cruz-de-tenerife/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 806",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/tenerife/santa-ursula/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 807",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/toledo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 808",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/toledo/toledo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 809",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/valencia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 810",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/valencia/paterna/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 811",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/valencia/valencia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 812",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/valladolid/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 813",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/valladolid/penafiel/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 814",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/viana-do-castelo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 815",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal-e-almoco/viana-do-castelo/viana-do-castelo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 816",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 817",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/a-coruna/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 818",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/a-coruna/carnota/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 819",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/a-coruna/ferrol/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 820",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/a-coruna/outes/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 821",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/acores/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 822",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/acores/ponta-delgada/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 823",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/alava/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 824",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/alava/laguardia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 825",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/alava/vitoria-gasteiz/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 826",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/albacete/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 827",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/albacete/albacete/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 828",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/alicante/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 829",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/alicante/alicante/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 830",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/alicante/altea/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 831",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/alicante/benidorm/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 832",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/alicante/calpe/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 833",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/alicante/denia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 834",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/alicante/l-alfas-del-pi/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 835",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/alicante/rojales/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 836",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/alicante/teulada/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 837",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/almeria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 838",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/almeria/alhama-de-almeria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 839",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/almeria/almeria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 840",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/almeria/roquetas-de-mar/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 841",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/andalucia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 842",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/aragon/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 843",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/asturias/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 844",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/asturias/langreo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 845",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/asturias/parres/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 846",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/badajoz/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 847",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/badajoz/merida/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 848",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/barcelona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 849",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/barcelona/barcelona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 850",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/barcelona/caldes-de-montbui/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 851",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/barcelona/calella/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 852",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/barcelona/la-garriga/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 853",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/barcelona/sant-boi-de-llobregat/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 854",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/barcelona/sant-joan-despi/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 855",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/barcelona/seva/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 856",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/barcelona/sitges/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 857",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/barcelona/vallromanes/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 858",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/burgos/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 859",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/burgos/aranda-de-duero/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 860",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/caceres/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 861",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/caceres/caceres/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 862",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/cadiz/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 863",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/cadiz/cadiz/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 864",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/cadiz/chiclana-de-la-frontera/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 865",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/cadiz/grazalema/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 866",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/cadiz/novo-sancti-petri/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 867",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/cadiz/rota/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 868",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/cadiz/tarifa/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 869",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/cadiz/vejer-de-la-frontera/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 870",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/cadiz/zahara/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 871",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/canarias/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 872",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/cantabria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 873",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/cantabria/la-hermida/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 874",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/cantabria/medio-cudeyo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 875",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/cantabria/torrelavega/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 876",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/castellon/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 877",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/castellon/la-vilavella/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 878",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/castellon/montanejos/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 879",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/castellon/peniscola/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 880",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/castilla-la-mancha/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 881",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/castilla-y-leon/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 882",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/cataluna/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 883",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/ciudad-real/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 884",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/ciudad-real/almagro/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 885",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/comunidad-autonoma-de-cantabria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 886",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/comunidad-de-madrid/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 887",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/comunidad-valenciana/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 888",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/cordoba/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 889",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/cordoba/cordoba/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 890",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/cordoba/lucena/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 891",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/distrito-de-evora/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 892",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/distrito-de-faro/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 893",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/distrito-de-leiria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 894",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/distrito-de-lisboa/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 895",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/distrito-de-oporto/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 896",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/distrito-de-setubal/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 897",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/distrito-de-viana-do-castelo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 898",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/distrito-de-vila-real/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 899",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/escaldes-engordany/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 900",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/escaldes-engordany/les-escaldes/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 901",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/evora/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 902",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/evora/evora/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 903",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/extremadura/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 904",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/faro/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 905",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/faro/albufeira/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 906",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/faro/monchique/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 907",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/faro/monte-gordo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 908",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/faro/portimao/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 909",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/fuerteventura/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 910",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/fuerteventura/antigua/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 911",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/fuerteventura/pajara/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 912",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/galicia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 913",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/girona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 914",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/girona/caldes-de-malavella/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 915",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/girona/llanars/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 916",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/girona/lloret-de-mar/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 917",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/girona/sant-feliu-de-guixols/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 918",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/girona/toses/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 919",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/gran-canaria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 920",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/gran-canaria/agaete/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 921",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/gran-canaria/las-palmas-de-gran-canaria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 922",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/gran-canaria/maspalomas/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 923",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/gran-canaria/mogan/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 924",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/gran-canaria/san-agustin-maspalomas/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 925",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/gran-canaria/san-bartolome-de-tirajana/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 926",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/granada/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 927",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/granada/almunecar/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 928",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/granada/alpujarra-de-la-sierra/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 929",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/granada/granada/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 930",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/granada/loja/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 931",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/granada/motril/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 932",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/guadalajara/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 933",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/guadalajara/abanades/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 934",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/guadalajara/brihuega/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 935",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/guipuzcoa/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 936",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/guipuzcoa/orio/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 937",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/huelva/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 938",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/huelva/ayamonte/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 939",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/huelva/cartaya/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 940",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/huelva/isla-cristina/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 941",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/huelva/punta-umbria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 942",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/huesca/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 943",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/huesca/monzon/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 944",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/huesca/sallent-de-gallego/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 945",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/islas-baleares/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 946",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/jaen/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 947",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/jaen/ubeda/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 948",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/lanzarote/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 949",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/lanzarote/puerto-del-carmen/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 950",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/lanzarote/teguise/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 951",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/lanzarote/yaiza/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 952",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/leiria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 953",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/leiria/alcobaca/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 954",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/leiria/monte-real/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 955",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/leiria/nazare/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 956",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/leiria/vestiaria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 957",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/leon/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 958",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/leon/astorga/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 959",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/lisboa/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 960",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/lisboa/ericeira/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 961",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/lisboa/lisboa/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 962",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/lisboa/torres-vedras/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 963",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/lleida/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 964",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/lleida/caldes-de-boi/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 965",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/lleida/vallbona-de-les-monges/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 966",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/lleida/vielha/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 967",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/lugo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 968",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/lugo/panton/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 969",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/lugo/vilalba/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 970",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/madeira/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 971",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/madeira/funchal/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 972",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/madrid/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 973",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/madrid/madrid/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 974",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/malaga/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 975",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/malaga/benalmadena/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 976",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/malaga/estepona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 977",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/malaga/fuengirola/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 978",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/malaga/malaga/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 979",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/malaga/marbella/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 980",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/malaga/mijas/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 981",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/malaga/torremolinos/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 982",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/mallorca/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 983",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/mallorca/calvia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 984",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/mallorca/colonia-sant-jordi/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 985",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/mallorca/felanitx/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 986",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/mallorca/llucmajor/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 987",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/mallorca/muro/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 988",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/mallorca/palma/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 989",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/mallorca/soller/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 990",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/mallorca/son-servera/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 991",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/menorca/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 992",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/menorca/ciutadella-de-menorca/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 993",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/murcia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 994",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/murcia/archena/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 995",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/murcia/la-manga-del-mar-menor/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 996",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/murcia/los-alcazares/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 997",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/murcia/murcia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 998",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/oporto/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 999",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/oporto/vila-do-conde/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1000",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/pais-vasco/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1001",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/paroquia-de-escaldes-engordany/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1002",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/pontevedra/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1003",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/pontevedra/moana/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1004",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/pontevedra/pontevedra/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1005",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/pontevedra/tui/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1006",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/pontevedra/vigo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1007",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/principado-de-asturias/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1008",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/regiao-de-acores/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1009",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/regiao-de-madeira/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1010",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/region-de-murcia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1011",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/salamanca/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1012",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/salamanca/bermellar/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1013",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/segovia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1014",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/segovia/santo-tome-del-puerto/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1015",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/segovia/segovia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1016",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/setubal/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1017",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/setubal/santiago-do-cacem/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1018",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/sevilla/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1019",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/sevilla/sevilla/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1020",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/sevilla/umbrete/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1021",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/soria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1022",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/soria/burgo-de-osma-ciudad-de-osma/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1023",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/tarragona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1024",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/tarragona/el-perello/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1025",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/tarragona/montbrio-del-camp/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1026",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/tarragona/salou/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1027",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/tarragona/torroja-del-priorat/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1028",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/tenerife/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1029",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/tenerife/adeje/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1030",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/tenerife/arona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1031",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/tenerife/guia-de-isora/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1032",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/tenerife/playa-de-las-americas/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1033",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/tenerife/puerto-de-la-cruz/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1034",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/tenerife/san-cristobal-de-la-laguna/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1035",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/tenerife/santa-cruz-de-tenerife/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1036",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/tenerife/santa-ursula/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1037",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/toledo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1038",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/toledo/carranque/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1039",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/toledo/toledo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1040",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/valencia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1041",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/valencia/gandia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1042",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/valencia/paterna/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1043",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/valencia/valencia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1044",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/valladolid/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1045",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/valladolid/olmedo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1046",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/valladolid/penafiel/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1047",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/valladolid/san-bernarndo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1048",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/viana-do-castelo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1049",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/viana-do-castelo/arcos-de-valdevez/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1050",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/viana-do-castelo/viana-do-castelo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1051",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/vila-real/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1052",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/vila-real/boticas/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1053",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/vila-real/ribeira-de-pena/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1054",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/vizcaya/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1055",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/circuito-termal/vizcaya/urduna/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1056",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/daypass-spa/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1057",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/daypass-spa/alicante/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1058",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/daypass-spa/alicante/alicante/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1059",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/daypass-spa/alicante/calpe/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1060",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/daypass-spa/andalucia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1061",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/daypass-spa/barcelona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1062",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/daypass-spa/barcelona/barcelona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1063",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/daypass-spa/caceres/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1064",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/daypass-spa/caceres/caceres/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1065",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/daypass-spa/cadiz/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1066",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/daypass-spa/cadiz/tarifa/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1067",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/daypass-spa/canarias/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1068",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/daypass-spa/cataluna/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1069",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/daypass-spa/comunidad-valenciana/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1070",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/daypass-spa/distrito-de-faro/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1071",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/daypass-spa/distrito-de-lisboa/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1072",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/daypass-spa/distrito-de-viana-do-castelo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1073",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/daypass-spa/extremadura/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1074",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/daypass-spa/faro/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1075",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/daypass-spa/faro/monte-gordo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1076",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/daypass-spa/galicia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1077",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/daypass-spa/girona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1078",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/daypass-spa/girona/torrent/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1079",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/daypass-spa/gran-canaria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1080",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/daypass-spa/gran-canaria/maspalomas/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1081",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/daypass-spa/gran-canaria/mogan/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1082",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/daypass-spa/gran-canaria/san-bartolome-de-tirajana/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1083",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/daypass-spa/huelva/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1084",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/daypass-spa/huelva/isla-cristina/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1085",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/daypass-spa/islas-baleares/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1086",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/daypass-spa/lisboa/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1087",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/daypass-spa/lisboa/ericeira/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1088",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/daypass-spa/lugo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1089",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/daypass-spa/lugo/vilalba/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1090",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/daypass-spa/mallorca/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1091",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/daypass-spa/mallorca/cala-millor/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1092",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/daypass-spa/mallorca/calvia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1093",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/daypass-spa/mallorca/felanitx/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1094",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/daypass-spa/mallorca/llucmajor/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1095",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/daypass-spa/mallorca/palma/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1096",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/daypass-spa/mallorca/soller/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1097",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/daypass-spa/pontevedra/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1098",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/daypass-spa/pontevedra/pontevedra/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1099",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/daypass-spa/sevilla/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1100",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/daypass-spa/sevilla/sevilla/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1101",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/daypass-spa/tenerife/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1102",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/daypass-spa/tenerife/adeje/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1103",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/daypass-spa/tenerife/puerto-de-la-cruz/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1104",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/daypass-spa/viana-do-castelo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1105",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/daypass-spa/viana-do-castelo/viana-do-castelo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1106",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/descontos/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1107",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/descontos/alava/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1108",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/descontos/alava/laguardia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1109",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/descontos/alicante/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1110",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/descontos/alicante/alicante/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1111",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/descontos/alicante/altea/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1112",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/descontos/alicante/calpe/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1113",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/descontos/alicante/denia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1114",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/descontos/almeria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1115",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/descontos/almeria/almeria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1116",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/descontos/almeria/roquetas-de-mar/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1117",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/descontos/andalucia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1118",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/descontos/barcelona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1119",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/descontos/barcelona/barcelona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1120",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/descontos/barcelona/la-garriga/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1121",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/descontos/barcelona/sant-boi-de-llobregat/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1122",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/descontos/barcelona/seva/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1123",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/descontos/burgos/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1124",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/descontos/burgos/aranda-de-duero/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1125",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/descontos/caceres/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1126",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/descontos/caceres/caceres/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1127",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/descontos/cadiz/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1128",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/descontos/cadiz/cadiz/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1129",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/descontos/cadiz/tarifa/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1130",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/descontos/cadiz/zahara/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1131",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/descontos/canarias/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1132",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/descontos/castilla-la-mancha/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1133",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/descontos/castilla-y-leon/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1134",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/descontos/cataluna/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1135",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/descontos/comunidad-de-madrid/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1136",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/descontos/comunidad-valenciana/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1137",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/descontos/cordoba/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1138",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/descontos/cordoba/cordoba/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1139",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/descontos/cordoba/lucena/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1140",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/descontos/distrito-de-lisboa/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1141",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/descontos/extremadura/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1142",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/descontos/fuerteventura/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1143",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/descontos/fuerteventura/pajara/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1144",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/descontos/girona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1145",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/descontos/girona/torrent/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1146",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/descontos/gran-canaria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1147",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/descontos/gran-canaria/maspalomas/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1148",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/descontos/gran-canaria/mogan/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1149",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/descontos/gran-canaria/san-bartolome-de-tirajana/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1150",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/descontos/granada/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1151",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/descontos/granada/almunecar/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1152",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/descontos/granada/granada/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1153",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/descontos/huelva/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1154",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/descontos/huelva/isla-cristina/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1155",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/descontos/islas-baleares/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1156",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/descontos/lisboa/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1157",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/descontos/lisboa/lisboa/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1158",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/descontos/madrid/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1159",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/descontos/madrid/madrid/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1160",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/descontos/malaga/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1161",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/descontos/malaga/estepona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1162",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/descontos/malaga/fuengirola/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1163",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/descontos/malaga/mijas/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1164",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/descontos/mallorca/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1165",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/descontos/mallorca/calvia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1166",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/descontos/pais-vasco/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1167",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/descontos/sevilla/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1168",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/descontos/sevilla/sevilla/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1169",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/descontos/tarragona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1170",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/descontos/tarragona/montbrio-del-camp/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1171",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/descontos/tarragona/salou/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1172",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/descontos/tenerife/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1173",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/descontos/tenerife/adeje/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1174",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/descontos/tenerife/arona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1175",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/descontos/tenerife/guia-de-isora/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1176",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/descontos/tenerife/santa-cruz-de-tenerife/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1177",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/descontos/toledo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1178",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/descontos/toledo/toledo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1179",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/descontos/valencia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1180",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/descontos/valencia/valencia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1181",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1182",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/a-coruna/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1183",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/a-coruna/ferrol/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1184",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/alava/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1185",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/alava/laguardia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1186",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/alava/vitoria-gasteiz/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1187",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/alicante/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1188",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/alicante/alicante/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1189",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/alicante/denia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1190",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/almeria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1191",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/almeria/alhama-de-almeria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1192",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/almeria/almeria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1193",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/andalucia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1194",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/aragon/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1195",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/barcelona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1196",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/barcelona/barcelona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1197",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/barcelona/berga/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1198",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/barcelona/caldes-de-montbui/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1199",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/barcelona/calella/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1200",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/barcelona/sant-boi-de-llobregat/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1201",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/barcelona/sant-joan-despi/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1202",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/barcelona/seva/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1203",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/barcelona/sitges/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1204",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/burgos/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1205",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/burgos/aranda-de-duero/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1206",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/caceres/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1207",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/caceres/caceres/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1208",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/cadiz/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1209",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/cadiz/chiclana-de-la-frontera/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1210",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/cadiz/grazalema/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1211",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/cadiz/tarifa/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1212",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/cadiz/zahara/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1213",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/canarias/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1214",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/castellon/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1215",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/castellon/la-vilavella/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1216",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/castilla-la-mancha/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1217",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/castilla-y-leon/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1218",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/cataluna/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1219",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/ciudad-real/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1220",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/ciudad-real/almagro/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1221",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/comunidad-de-madrid/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1222",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/comunidad-valenciana/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1223",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/cordoba/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1224",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/cordoba/cordoba/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1225",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/cordoba/lucena/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1226",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/extremadura/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1227",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/fuerteventura/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1228",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/fuerteventura/pajara/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1229",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/galicia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1230",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/gran-canaria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1231",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/gran-canaria/maspalomas/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1232",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/gran-canaria/mogan/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1233",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/gran-canaria/san-agustin-maspalomas/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1234",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/gran-canaria/san-bartolome-de-tirajana/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1235",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/granada/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1236",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/granada/almunecar/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1237",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/granada/alpujarra-de-la-sierra/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1238",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/granada/granada/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1239",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/guadalajara/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1240",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/guadalajara/abanades/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1241",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/huelva/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1242",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/huelva/ayamonte/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1243",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/huelva/cartaya/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1244",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/huelva/costa-occidental/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1245",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/huelva/isla-cristina/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1246",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/huelva/punta-umbria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1247",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/huesca/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1248",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/huesca/huesca/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1249",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/huesca/sallent-de-gallego/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1250",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/islas-baleares/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1251",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/lanzarote/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1252",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/lanzarote/puerto-del-carmen/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1253",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/lanzarote/teguise/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1254",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/lanzarote/yaiza/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1255",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/lugo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1256",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/lugo/burela/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1257",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/lugo/vilalba/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1258",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/madrid/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1259",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/madrid/madrid/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1260",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/malaga/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1261",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/malaga/fuengirola/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1262",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/mallorca/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1263",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/mallorca/calvia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1264",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/mallorca/colonia-sant-jordi/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1265",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/mallorca/montuiri/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1266",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/mallorca/palma/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1267",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/mallorca/soller/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1268",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/menorca/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1269",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/menorca/ciutadella-de-menorca/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1270",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/murcia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1271",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/murcia/archena/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1272",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/pais-vasco/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1273",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/pontevedra/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1274",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/pontevedra/pontevedra/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1275",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/pontevedra/vigo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1276",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/region-de-murcia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1277",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/salamanca/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1278",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/salamanca/bermellar/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1279",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/segovia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1280",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/segovia/segovia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1281",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/sevilla/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1282",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/sevilla/sevilla/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1283",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/sevilla/umbrete/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1284",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/tarragona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1285",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/tarragona/salou/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1286",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/tenerife/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1287",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/tenerife/adeje/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1288",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/tenerife/arona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1289",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/tenerife/guia-de-isora/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1290",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/tenerife/playa-de-las-americas/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1291",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/tenerife/puerto-de-la-cruz/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1292",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/tenerife/san-cristobal-de-la-laguna/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1293",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/tenerife/santa-ursula/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1294",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/toledo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1295",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/toledo/carranque/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1296",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/toledo/toledo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1297",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/valencia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1298",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-da-mae/valencia/valencia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1299",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-das-ilhas-canarias/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1300",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-das-ilhas-canarias/canarias/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1301",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-das-ilhas-canarias/gran-canaria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1302",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-das-ilhas-canarias/gran-canaria/maspalomas/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1303",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1304",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/a-coruna/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1305",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/a-coruna/ferrol/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1306",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/alava/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1307",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/alava/laguardia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1308",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/alava/vitoria-gasteiz/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1309",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/almeria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1310",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/almeria/alhama-de-almeria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1311",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/almeria/almeria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1312",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/andalucia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1313",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/aragon/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1314",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/barcelona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1315",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/barcelona/barcelona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1316",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/barcelona/berga/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1317",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/barcelona/caldes-de-montbui/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1318",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/barcelona/la-garriga/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1319",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/barcelona/sant-boi-de-llobregat/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1320",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/barcelona/sant-joan-despi/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1321",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/barcelona/sitges/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1322",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/burgos/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1323",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/burgos/aranda-de-duero/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1324",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/caceres/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1325",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/caceres/caceres/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1326",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/cadiz/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1327",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/cadiz/grazalema/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1328",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/cadiz/tarifa/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1329",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/cadiz/zahara/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1330",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/canarias/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1331",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/castellon/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1332",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/castellon/la-vilavella/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1333",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/castilla-la-mancha/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1334",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/castilla-y-leon/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1335",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/cataluna/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1336",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/ciudad-real/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1337",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/ciudad-real/almagro/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1338",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/comunidad-de-madrid/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1339",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/comunidad-valenciana/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1340",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/cordoba/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1341",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/cordoba/cordoba/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1342",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/cordoba/lucena/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1343",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/distrito-de-lisboa/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1344",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/extremadura/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1345",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/fuerteventura/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1346",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/fuerteventura/antigua/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1347",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/fuerteventura/pajara/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1348",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/galicia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1349",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/girona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1350",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/girona/torrent/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1351",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/gran-canaria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1352",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/gran-canaria/maspalomas/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1353",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/gran-canaria/mogan/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1354",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/gran-canaria/san-agustin-maspalomas/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1355",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/gran-canaria/san-bartolome-de-tirajana/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1356",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/granada/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1357",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/granada/alpujarra-de-la-sierra/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1358",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/granada/granada/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1359",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/guadalajara/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1360",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/guadalajara/abanades/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1361",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/huelva/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1362",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/huelva/ayamonte/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1363",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/huelva/cartaya/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1364",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/huelva/costa-occidental/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1365",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/huelva/punta-umbria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1366",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/huesca/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1367",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/huesca/huesca/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1368",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/huesca/sallent-de-gallego/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1369",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/islas-baleares/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1370",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/lanzarote/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1371",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/lanzarote/yaiza/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1372",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/lisboa/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1373",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/lisboa/ericeira/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1374",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/lugo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1375",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/lugo/burela/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1376",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/madrid/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1377",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/madrid/madrid/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1378",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/malaga/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1379",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/malaga/estepona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1380",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/malaga/fuengirola/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1381",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/mallorca/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1382",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/mallorca/calvia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1383",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/mallorca/montuiri/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1384",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/mallorca/palma/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1385",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/mallorca/sant-llorenc-des-cardassar/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1386",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/mallorca/soller/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1387",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/menorca/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1388",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/menorca/ciutadella-de-menorca/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1389",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/murcia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1390",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/murcia/archena/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1391",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/pais-vasco/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1392",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/pontevedra/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1393",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/pontevedra/pontevedra/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1394",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/pontevedra/vigo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1395",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/region-de-murcia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1396",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/salamanca/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1397",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/salamanca/bermellar/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1398",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/segovia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1399",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/segovia/segovia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1400",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/sevilla/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1401",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/sevilla/sevilla/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1402",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/sevilla/umbrete/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1403",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/tarragona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1404",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/tarragona/el-perello/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1405",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/tarragona/salou/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1406",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/tenerife/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1407",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/tenerife/adeje/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1408",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/tenerife/arona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1409",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/tenerife/guia-de-isora/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1410",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/tenerife/playa-de-las-americas/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1411",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/tenerife/puerto-de-la-cruz/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1412",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/tenerife/san-cristobal-de-la-laguna/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1413",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/tenerife/santa-cruz-de-tenerife/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1414",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/tenerife/santa-ursula/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1415",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/toledo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1416",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/toledo/carranque/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1417",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/toledo/toledo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1418",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/valencia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1419",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-do-pai/valencia/valencia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1420",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-dos-namorados/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1421",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-dos-namorados/a-coruna/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1422",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-dos-namorados/a-coruna/ferrol/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1423",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-dos-namorados/alicante/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1424",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-dos-namorados/alicante/alicante/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1425",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-dos-namorados/alicante/denia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1426",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-dos-namorados/andalucia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1427",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-dos-namorados/barcelona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1428",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-dos-namorados/barcelona/barcelona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1429",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-dos-namorados/barcelona/la-garriga/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1430",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-dos-namorados/barcelona/seva/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1431",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-dos-namorados/caceres/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1432",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-dos-namorados/caceres/caceres/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1433",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-dos-namorados/cadiz/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1434",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-dos-namorados/cadiz/cadiz/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1435",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-dos-namorados/canarias/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1436",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-dos-namorados/castilla-la-mancha/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1437",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-dos-namorados/cataluna/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1438",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-dos-namorados/comunidad-valenciana/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1439",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-dos-namorados/distrito-de-lisboa/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1440",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-dos-namorados/distrito-de-viana-do-castelo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1441",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-dos-namorados/extremadura/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1442",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-dos-namorados/fuerteventura/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1443",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-dos-namorados/fuerteventura/pajara/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1444",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-dos-namorados/galicia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1445",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-dos-namorados/gran-canaria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1446",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-dos-namorados/gran-canaria/maspalomas/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1447",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-dos-namorados/gran-canaria/mogan/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1448",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-dos-namorados/gran-canaria/san-bartolome-de-tirajana/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1449",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-dos-namorados/granada/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1450",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-dos-namorados/granada/granada/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1451",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-dos-namorados/guipuzcoa/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1452",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-dos-namorados/guipuzcoa/orio/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1453",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-dos-namorados/islas-baleares/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1454",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-dos-namorados/lisboa/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1455",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-dos-namorados/lisboa/ericeira/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1456",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-dos-namorados/lisboa/lisboa/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1457",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-dos-namorados/malaga/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1458",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-dos-namorados/malaga/estepona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1459",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-dos-namorados/malaga/fuengirola/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1460",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-dos-namorados/malaga/malaga/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1461",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-dos-namorados/mallorca/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1462",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-dos-namorados/mallorca/calvia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1463",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-dos-namorados/pais-vasco/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1464",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-dos-namorados/pontevedra/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1465",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-dos-namorados/pontevedra/moana/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1466",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-dos-namorados/tenerife/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1467",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-dos-namorados/tenerife/adeje/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1468",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-dos-namorados/tenerife/arona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1469",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-dos-namorados/tenerife/santa-cruz-de-tenerife/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1470",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-dos-namorados/tenerife/santa-ursula/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1471",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-dos-namorados/toledo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1472",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-dos-namorados/toledo/toledo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1473",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-dos-namorados/valencia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1474",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-dos-namorados/valencia/valencia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1475",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-dos-namorados/viana-do-castelo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1476",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/dia-dos-namorados/viana-do-castelo/viana-do-castelo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1477",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1478",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/a-coruna/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1479",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/a-coruna/ferrol/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1480",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/alava/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1481",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/alava/laguardia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1482",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/alava/vitoria-gasteiz/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1483",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/alicante/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1484",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/alicante/alicante/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1485",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/alicante/altea/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1486",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/alicante/benidorm/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1487",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/alicante/denia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1488",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/alicante/l-alfas-del-pi/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1489",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/alicante/rojales/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1490",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/almeria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1491",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/almeria/alhama-de-almeria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1492",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/almeria/almeria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1493",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/andalucia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1494",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/aragon/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1495",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/asturias/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1496",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/asturias/parres/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1497",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/badajoz/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1498",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/badajoz/merida/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1499",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/barcelona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1500",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/barcelona/barcelona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1501",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/barcelona/berga/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1502",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/barcelona/caldes-de-montbui/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1503",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/barcelona/calella/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1504",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/barcelona/la-garriga/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1505",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/barcelona/sant-boi-de-llobregat/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1506",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/barcelona/sant-joan-despi/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1507",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/barcelona/seva/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1508",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/barcelona/sitges/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1509",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/burgos/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1510",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/burgos/aranda-de-duero/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1511",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/caceres/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1512",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/caceres/caceres/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1513",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/cadiz/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1514",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/cadiz/cadiz/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1515",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/cadiz/chiclana-de-la-frontera/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1516",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/cadiz/grazalema/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1517",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/cadiz/novo-sancti-petri/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1518",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/cadiz/rota/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1519",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/cadiz/tarifa/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1520",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/cadiz/zahara/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1521",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/canarias/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1522",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/cantabria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1523",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/cantabria/torrelavega/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1524",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/castellon/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1525",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/castellon/la-vilavella/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1526",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/castellon/montanejos/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1527",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/castilla-la-mancha/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1528",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/castilla-y-leon/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1529",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/cataluna/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1530",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/ciudad-real/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1531",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/ciudad-real/almagro/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1532",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/comunidad-autonoma-de-cantabria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1533",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/comunidad-de-madrid/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1534",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/comunidad-valenciana/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1535",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/cordoba/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1536",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/cordoba/cordoba/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1537",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/cordoba/lucena/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1538",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/distrito-de-lisboa/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1539",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/extremadura/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1540",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/fuerteventura/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1541",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/fuerteventura/antigua/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1542",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/fuerteventura/pajara/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1543",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/galicia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1544",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/girona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1545",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/girona/caldes-de-malavella/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1546",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/girona/torrent/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1547",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/gran-canaria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1548",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/gran-canaria/agaete/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1549",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/gran-canaria/maspalomas/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1550",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/gran-canaria/mogan/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1551",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/gran-canaria/san-agustin-maspalomas/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1552",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/gran-canaria/san-bartolome-de-tirajana/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1553",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/granada/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1554",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/granada/almunecar/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1555",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/granada/alpujarra-de-la-sierra/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1556",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/granada/granada/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1557",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/granada/lanjaron/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1558",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/granada/niguelas/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1559",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/guadalajara/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1560",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/guadalajara/abanades/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1561",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/guipuzcoa/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1562",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/guipuzcoa/orio/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1563",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/huelva/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1564",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/huelva/ayamonte/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1565",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/huelva/cartaya/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1566",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/huelva/costa-occidental/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1567",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/huelva/isla-cristina/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1568",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/huelva/punta-umbria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1569",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/huesca/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1570",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/huesca/sallent-de-gallego/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1571",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/islas-baleares/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1572",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/lanzarote/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1573",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/lanzarote/puerto-del-carmen/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1574",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/lanzarote/teguise/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1575",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/lanzarote/yaiza/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1576",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/leon/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1577",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/leon/astorga/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1578",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/lisboa/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1579",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/lisboa/ericeira/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1580",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/lugo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1581",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/lugo/burela/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1582",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/lugo/vilalba/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1583",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/madrid/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1584",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/madrid/madrid/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1585",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/malaga/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1586",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/malaga/estepona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1587",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/malaga/fuengirola/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1588",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/malaga/marbella/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1589",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/mallorca/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1590",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/mallorca/cala-millor/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1591",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/mallorca/calvia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1592",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/mallorca/montuiri/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1593",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/mallorca/palma/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1594",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/mallorca/soller/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1595",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/menorca/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1596",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/menorca/ciutadella-de-menorca/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1597",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/murcia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1598",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/murcia/archena/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1599",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/murcia/los-alcazares/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1600",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/pais-vasco/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1601",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/pontevedra/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1602",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/pontevedra/pontevedra/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1603",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/pontevedra/vigo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1604",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/principado-de-asturias/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1605",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/region-de-murcia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1606",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/salamanca/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1607",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/salamanca/bermellar/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1608",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/segovia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1609",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/segovia/santo-tome-del-puerto/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1610",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/segovia/segovia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1611",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/sevilla/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1612",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/sevilla/sevilla/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1613",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/sevilla/umbrete/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1614",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/tarragona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1615",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/tarragona/el-perello/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1616",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/tarragona/salou/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1617",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/tenerife/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1618",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/tenerife/adeje/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1619",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/tenerife/arona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1620",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/tenerife/guia-de-isora/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1621",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/tenerife/playa-de-las-americas/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1622",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/tenerife/puerto-de-la-cruz/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1623",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/tenerife/san-cristobal-de-la-laguna/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1624",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/tenerife/santa-cruz-de-tenerife/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1625",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/tenerife/santa-ursula/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1626",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/toledo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1627",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/toledo/carranque/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1628",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/toledo/toledo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1629",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/valencia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1630",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/valencia/gandia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1631",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/valencia/paterna/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1632",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/especial-de-natal/valencia/valencia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1633",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1634",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/a-coruna/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1635",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/a-coruna/outes/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1636",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/acores/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1637",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/acores/ponta-delgada/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1638",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/albacete/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1639",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/albacete/albacete/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1640",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/alicante/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1641",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/alicante/alicante/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1642",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/alicante/benidorm/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1643",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/alicante/calpe/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1644",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/alicante/rojales/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1645",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/almeria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1646",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/almeria/almeria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1647",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/andalucia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1648",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/aragon/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1649",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/badajoz/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1650",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/badajoz/merida/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1651",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/barcelona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1652",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/barcelona/barcelona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1653",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/barcelona/caldes-de-montbui/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1654",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/barcelona/la-garriga/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1655",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/barcelona/sant-boi-de-llobregat/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1656",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/barcelona/sitges/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1657",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/caceres/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1658",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/caceres/caceres/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1659",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/cadiz/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1660",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/cadiz/cadiz/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1661",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/cadiz/chiclana-de-la-frontera/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1662",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/cadiz/grazalema/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1663",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/cadiz/novo-sancti-petri/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1664",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/cadiz/tarifa/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1665",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/cadiz/zahara/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1666",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/canarias/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1667",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/castilla-la-mancha/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1668",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/cataluna/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1669",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/comunidad-de-madrid/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1670",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/comunidad-valenciana/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1671",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/cordoba/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1672",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/cordoba/cordoba/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1673",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/cordoba/lucena/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1674",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/distrito-de-faro/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1675",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/distrito-de-leiria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1676",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/distrito-de-lisboa/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1677",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/distrito-de-oporto/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1678",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/distrito-de-setubal/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1679",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/distrito-de-viana-do-castelo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1680",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/distrito-de-vila-real/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1681",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/extremadura/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1682",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/faro/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1683",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/faro/albufeira/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1684",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/faro/portimao/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1685",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/galicia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1686",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/girona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1687",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/girona/caldes-de-malavella/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1688",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/girona/sant-feliu-de-guixols/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1689",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/gran-canaria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1690",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/gran-canaria/agaete/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1691",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/gran-canaria/maspalomas/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1692",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/gran-canaria/mogan/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1693",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/gran-canaria/san-agustin-maspalomas/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1694",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/gran-canaria/san-bartolome-de-tirajana/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1695",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/granada/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1696",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/granada/granada/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1697",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/granada/motril/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1698",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/granada/niguelas/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1699",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/guadalajara/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1700",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/guadalajara/abanades/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1701",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/huelva/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1702",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/huelva/cartaya/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1703",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/huelva/costa-occidental/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1704",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/huelva/isla-cristina/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1705",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/huesca/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1706",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/huesca/sallent-de-gallego/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1707",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/islas-baleares/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1708",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/lanzarote/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1709",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/lanzarote/puerto-del-carmen/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1710",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/lanzarote/teguise/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1711",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/lanzarote/yaiza/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1712",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/leiria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1713",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/leiria/alcobaca/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1714",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/lisboa/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1715",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/lisboa/ericeira/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1716",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/lisboa/lisboa/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1717",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/lisboa/torres-vedras/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1718",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/lugo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1719",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/lugo/vilalba/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1720",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/madrid/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1721",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/madrid/madrid/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1722",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/malaga/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1723",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/malaga/estepona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1724",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/malaga/fuengirola/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1725",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/malaga/marbella/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1726",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/mallorca/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1727",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/mallorca/cala-millor/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1728",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/mallorca/calvia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1729",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/mallorca/sant-llorenc-des-cardassar/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1730",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/mallorca/soller/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1731",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/oporto/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1732",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/oporto/vila-nova-de-gaia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1733",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/pontevedra/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1734",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/pontevedra/moana/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1735",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/pontevedra/pontevedra/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1736",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/regiao-de-acores/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1737",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/setubal/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1738",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/setubal/santiago-do-cacem/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1739",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/sevilla/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1740",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/sevilla/sevilla/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1741",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/tarragona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1742",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/tarragona/el-perello/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1743",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/tarragona/montbrio-del-camp/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1744",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/tarragona/salou/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1745",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/tenerife/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1746",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/tenerife/adeje/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1747",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/tenerife/arona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1748",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/tenerife/playa-de-las-americas/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1749",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/tenerife/puerto-de-la-cruz/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1750",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/tenerife/san-cristobal-de-la-laguna/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1751",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/tenerife/santa-ursula/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1752",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/toledo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1753",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/toledo/carranque/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1754",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/toledo/toledo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1755",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/valencia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1756",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/valencia/gandia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1757",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/valencia/valencia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1758",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/viana-do-castelo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1759",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/viana-do-castelo/viana-do-castelo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1760",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/vila-real/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1761",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/vila-real/boticas/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1762",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/massagem-para-dois/vila-real/ribeira-de-pena/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1763",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1764",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/a-coruna/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1765",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/a-coruna/outes/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1766",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/acores/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1767",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/acores/ponta-delgada/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1768",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/albacete/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1769",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/albacete/albacete/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1770",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/alicante/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1771",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/alicante/alicante/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1772",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/alicante/altea/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1773",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/alicante/calpe/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1774",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/alicante/l-alfas-del-pi/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1775",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/alicante/rojales/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1776",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/alicante/teulada/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1777",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/almeria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1778",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/almeria/almeria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1779",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/almeria/roquetas-de-mar/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1780",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/andalucia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1781",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/asturias/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1782",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/asturias/langreo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1783",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/asturias/parres/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1784",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/barcelona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1785",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/barcelona/barcelona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1786",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/barcelona/caldes-de-montbui/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1787",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/barcelona/seva/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1788",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/barcelona/sitges/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1789",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/cadiz/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1790",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/cadiz/cadiz/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1791",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/cadiz/chiclana-de-la-frontera/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1792",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/cadiz/novo-sancti-petri/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1793",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/cadiz/rota/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1794",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/cadiz/vejer-de-la-frontera/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1795",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/cadiz/zahara/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1796",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/canarias/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1797",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/cantabria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1798",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/cantabria/medio-cudeyo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1799",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/cantabria/torrelavega/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1800",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/castellon/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1801",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/castellon/montanejos/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1802",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/castellon/peniscola/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1803",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/castilla-la-mancha/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1804",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/castilla-y-leon/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1805",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/cataluna/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1806",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/comunidad-autonoma-de-cantabria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1807",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/comunidad-de-madrid/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1808",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/comunidad-valenciana/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1809",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/distrito-de-lisboa/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1810",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/distrito-de-setubal/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1811",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/distrito-de-viana-do-castelo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1812",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/fuerteventura/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1813",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/fuerteventura/antigua/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1814",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/fuerteventura/pajara/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1815",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/galicia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1816",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/girona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1817",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/girona/llanars/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1818",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/girona/sant-feliu-de-guixols/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1819",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/gran-canaria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1820",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/gran-canaria/maspalomas/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1821",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/gran-canaria/mogan/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1822",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/granada/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1823",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/granada/almunecar/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1824",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/granada/granada/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1825",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/granada/loja/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1826",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/granada/monachil/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1827",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/granada/niguelas/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1828",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/guadalajara/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1829",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/guadalajara/brihuega/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1830",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/guipuzcoa/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1831",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/guipuzcoa/hondarribia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1832",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/guipuzcoa/orio/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1833",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/huelva/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1834",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/huelva/ayamonte/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1835",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/huelva/cartaya/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1836",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/islas-baleares/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1837",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/lisboa/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1838",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/lisboa/lisboa/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1839",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/lleida/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1840",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/lleida/caldes-de-boi/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1841",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/lleida/vielha/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1842",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/lugo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1843",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/lugo/vilalba/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1844",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/madrid/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1845",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/madrid/madrid/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1846",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/malaga/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1847",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/malaga/estepona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1848",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/malaga/fuengirola/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1849",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/malaga/malaga/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1850",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/malaga/marbella/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1851",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/malaga/mijas/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1852",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/mallorca/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1853",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/mallorca/palma/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1854",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/mallorca/sant-llorenc-des-cardassar/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1855",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/mallorca/son-servera/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1856",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/murcia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1857",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/murcia/archena/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1858",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/murcia/los-alcazares/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1859",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/murcia/murcia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1860",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/pais-vasco/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1861",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/pontevedra/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1862",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/pontevedra/moana/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1863",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/pontevedra/vigo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1864",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/principado-de-asturias/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1865",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/regiao-de-acores/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1866",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/region-de-murcia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1867",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/segovia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1868",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/segovia/santo-tome-del-puerto/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1869",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/setubal/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1870",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/setubal/santiago-do-cacem/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1871",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/sevilla/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1872",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/sevilla/sevilla/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1873",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/soria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1874",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/soria/burgo-de-osma-ciudad-de-osma/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1875",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/tarragona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1876",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/tarragona/torroja-del-priorat/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1877",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/tenerife/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1878",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/tenerife/adeje/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1879",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/tenerife/guia-de-isora/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1880",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/tenerife/puerto-de-la-cruz/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1881",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/tenerife/santa-cruz-de-tenerife/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1882",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/tenerife/santa-ursula/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1883",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/valencia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1884",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/valencia/paterna/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1885",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/valencia/valencia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1886",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/valladolid/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1887",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/valladolid/olmedo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1888",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/valladolid/penafiel/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1889",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/valladolid/san-bernarndo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1890",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/viana-do-castelo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1891",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/viana-do-castelo/caminha/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1892",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/vizcaya/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1893",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/recomendado/vizcaya/urduna/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1894",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-de-aniversario/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1895",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-de-aniversario/alicante/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1896",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-de-aniversario/alicante/alicante/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1897",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-de-aniversario/andalucia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1898",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-de-aniversario/barcelona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1899",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-de-aniversario/barcelona/barcelona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1900",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-de-aniversario/canarias/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1901",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-de-aniversario/cataluna/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1902",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-de-aniversario/comunidad-valenciana/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1903",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-de-aniversario/granada/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1904",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-de-aniversario/granada/granada/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1905",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-de-aniversario/tenerife/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1906",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-de-aniversario/tenerife/adeje/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1907",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-noturno/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1908",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-noturno/canarias/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1909",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-noturno/comunidad-valenciana/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1910",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-noturno/gran-canaria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1911",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-noturno/gran-canaria/maspalomas/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1912",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-noturno/islas-baleares/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1913",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-noturno/mallorca/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1914",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-noturno/mallorca/calvia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1915",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-noturno/tenerife/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1916",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-noturno/tenerife/adeje/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1917",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-noturno/tenerife/santa-cruz-de-tenerife/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1918",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-noturno/valencia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1919",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-noturno/valencia/valencia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1920",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1921",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/a-coruna/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1922",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/a-coruna/ferrol/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1923",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/a-coruna/outes/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1924",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/acores/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1925",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/acores/ponta-delgada/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1926",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/alava/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1927",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/alava/laguardia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1928",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/alava/vitoria-gasteiz/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1929",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/albacete/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1930",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/albacete/albacete/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1931",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/alicante/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1932",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/alicante/alicante/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1933",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/alicante/altea/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1934",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/alicante/benidorm/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1935",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/alicante/calpe/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1936",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/alicante/denia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1937",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/alicante/l-alfas-del-pi/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1938",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/almeria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1939",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/almeria/alhama-de-almeria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1940",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/almeria/almeria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1941",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/almeria/roquetas-de-mar/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1942",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/andalucia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1943",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/aragon/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1944",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/badajoz/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1945",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/badajoz/merida/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1946",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/barcelona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1947",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/barcelona/barcelona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1948",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/barcelona/berga/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1949",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/barcelona/caldes-de-montbui/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1950",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/barcelona/calella/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1951",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/barcelona/la-garriga/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1952",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/barcelona/sant-boi-de-llobregat/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1953",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/barcelona/sant-joan-despi/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1954",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/burgos/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1955",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/burgos/aranda-de-duero/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1956",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/cadiz/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1957",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/cadiz/cadiz/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1958",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/cadiz/chiclana-de-la-frontera/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1959",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/cadiz/grazalema/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1960",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/cadiz/novo-sancti-petri/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1961",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/cadiz/tarifa/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1962",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/cadiz/zahara/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1963",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/canarias/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1964",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/cantabria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1965",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/cantabria/medio-cudeyo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1966",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/cantabria/torrelavega/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1967",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/castellon/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1968",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/castellon/peniscola/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1969",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/castilla-la-mancha/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1970",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/castilla-y-leon/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1971",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/cataluna/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1972",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/comunidad-autonoma-de-cantabria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1973",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/comunidad-de-madrid/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1974",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/comunidad-valenciana/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1975",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/cordoba/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1976",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/cordoba/cordoba/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1977",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/cordoba/lucena/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1978",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/distrito-de-faro/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1979",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/distrito-de-leiria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1980",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/distrito-de-lisboa/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1981",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/distrito-de-oporto/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1982",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/distrito-de-setubal/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1983",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/distrito-de-viana-do-castelo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1984",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/distrito-de-vila-real/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1985",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/extremadura/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1986",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/faro/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1987",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/faro/albufeira/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1988",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/faro/monchique/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1989",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/faro/portimao/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1990",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/fuerteventura/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1991",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/fuerteventura/antigua/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1992",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/galicia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1993",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/girona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1994",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/girona/caldes-de-malavella/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1995",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/girona/llanars/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1996",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/girona/lloret-de-mar/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1997",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/girona/sant-feliu-de-guixols/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1998",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/gran-canaria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 1999",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/gran-canaria/agaete/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2000",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/gran-canaria/maspalomas/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2001",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/gran-canaria/mogan/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2002",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/gran-canaria/san-agustin-maspalomas/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2003",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/gran-canaria/san-bartolome-de-tirajana/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2004",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/granada/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2005",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/granada/alpujarra-de-la-sierra/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2006",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/granada/granada/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2007",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/granada/motril/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2008",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/guadalajara/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2009",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/guadalajara/abanades/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2010",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/huelva/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2011",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/huelva/cartaya/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2012",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/huelva/costa-occidental/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2013",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/huelva/isla-cristina/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2014",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/huelva/punta-umbria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2015",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/huesca/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2016",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/huesca/sallent-de-gallego/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2017",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/islas-baleares/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2018",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/jaen/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2019",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/jaen/ubeda/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2020",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/lanzarote/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2021",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/lanzarote/teguise/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2022",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/lanzarote/yaiza/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2023",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/leiria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2024",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/leiria/alcobaca/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2025",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/leiria/monte-real/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2026",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/lisboa/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2027",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/lisboa/ericeira/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2028",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/lisboa/lisboa/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2029",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/lisboa/torres-vedras/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2030",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/lleida/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2031",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/lleida/vielha/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2032",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/lugo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2033",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/lugo/burela/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2034",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/madrid/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2035",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/madrid/madrid/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2036",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/malaga/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2037",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/malaga/estepona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2038",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/malaga/fuengirola/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2039",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/malaga/marbella/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2040",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/mallorca/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2041",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/mallorca/cala-millor/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2042",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/mallorca/calvia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2043",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/mallorca/colonia-sant-jordi/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2044",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/mallorca/palma/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2045",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/mallorca/sant-llorenc-des-cardassar/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2046",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/mallorca/soller/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2047",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/menorca/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2048",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/menorca/ciutadella-de-menorca/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2049",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/murcia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2050",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/murcia/archena/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2051",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/murcia/murcia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2052",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/oporto/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2053",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/oporto/vila-nova-de-gaia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2054",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/pais-vasco/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2055",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/pontevedra/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2056",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/pontevedra/moana/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2057",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/pontevedra/pontevedra/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2058",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/pontevedra/vigo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2059",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/regiao-de-acores/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2060",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/region-de-murcia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2061",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/salamanca/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2062",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/salamanca/bermellar/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2063",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/segovia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2064",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/segovia/santo-tome-del-puerto/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2065",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/segovia/segovia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2066",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/setubal/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2067",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/setubal/santiago-do-cacem/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2068",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/sevilla/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2069",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/sevilla/sevilla/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2070",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/sevilla/umbrete/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2071",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/tarragona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2072",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/tarragona/el-perello/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2073",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/tarragona/montbrio-del-camp/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2074",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/tarragona/salou/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2075",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/tarragona/torroja-del-priorat/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2076",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/tenerife/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2077",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/tenerife/adeje/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2078",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/tenerife/arona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2079",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/tenerife/guia-de-isora/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2080",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/tenerife/playa-de-las-americas/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2081",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/tenerife/puerto-de-la-cruz/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2082",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/tenerife/san-cristobal-de-la-laguna/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2083",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/tenerife/santa-cruz-de-tenerife/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2084",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/tenerife/santa-ursula/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2085",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/toledo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2086",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/toledo/carranque/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2087",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/toledo/toledo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2088",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/valencia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2089",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/valencia/gandia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2090",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/valencia/paterna/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2091",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/valencia/valencia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2092",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/viana-do-castelo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2093",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/viana-do-castelo/arcos-de-valdevez/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2094",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/viana-do-castelo/viana-do-castelo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2095",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/vila-real/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2096",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/vila-real/boticas/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2097",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-casais/vila-real/ribeira-de-pena/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2098",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-grupos/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2099",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-grupos/alicante/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2100",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-grupos/alicante/alicante/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2101",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-grupos/andalucia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2102",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-grupos/barcelona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2103",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-grupos/barcelona/barcelona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2104",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-grupos/canarias/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2105",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-grupos/castilla-y-leon/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2106",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-grupos/cataluna/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2107",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-grupos/comunidad-valenciana/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2108",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-grupos/distrito-de-lisboa/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2109",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-grupos/distrito-de-oporto/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2110",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-grupos/galicia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2111",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-grupos/granada/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2112",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-grupos/granada/granada/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2113",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-grupos/granada/motril/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2114",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-grupos/granada/niguelas/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2115",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-grupos/lisboa/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2116",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-grupos/lisboa/lisboa/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2117",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-grupos/malaga/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2118",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-grupos/malaga/malaga/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2119",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-grupos/oporto/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2120",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-grupos/oporto/sao-felix-da-marinha/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2121",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-grupos/oporto/vila-do-conde/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2122",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-grupos/pontevedra/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2123",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-grupos/pontevedra/vigo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2124",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-grupos/segovia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2125",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-grupos/segovia/santo-tome-del-puerto/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2126",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-grupos/tenerife/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2127",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-grupos/tenerife/adeje/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2128",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-grupos/valencia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2129",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-grupos/valencia/valencia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2130",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-grupos/valladolid/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2131",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-para-grupos/valladolid/penafiel/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2132",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2133",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/a-coruna/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2134",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/a-coruna/ferrol/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2135",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/alava/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2136",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/alava/laguardia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2137",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/alicante/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2138",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/alicante/alicante/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2139",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/alicante/benidorm/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2140",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/alicante/calpe/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2141",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/alicante/teulada/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2142",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/andalucia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2143",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/aragon/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2144",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/badajoz/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2145",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/badajoz/merida/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2146",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/barcelona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2147",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/barcelona/barcelona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2148",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/barcelona/vallromanes/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2149",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/burgos/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2150",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/burgos/aranda-de-duero/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2151",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/cadiz/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2152",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/cadiz/cadiz/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2153",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/cadiz/tarifa/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2154",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/cadiz/vejer-de-la-frontera/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2155",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/cadiz/zahara/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2156",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/canarias/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2157",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/castilla-la-mancha/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2158",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/castilla-y-leon/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2159",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/cataluna/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2160",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/comunidad-de-madrid/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2161",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/comunidad-valenciana/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2162",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/extremadura/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2163",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/galicia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2164",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/girona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2165",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/girona/lloret-de-mar/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2166",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/girona/sant-feliu-de-guixols/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2167",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/gran-canaria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2168",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/gran-canaria/maspalomas/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2169",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/gran-canaria/mogan/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2170",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/granada/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2171",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/granada/alpujarra-de-la-sierra/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2172",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/granada/granada/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2173",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/guadalajara/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2174",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/guadalajara/abanades/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2175",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/guipuzcoa/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2176",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/guipuzcoa/orio/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2177",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/huelva/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2178",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/huelva/isla-cristina/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2179",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/huelva/punta-umbria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2180",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/huesca/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2181",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/huesca/huesca/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2182",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/huesca/monzon/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2183",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/islas-baleares/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2184",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/lanzarote/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2185",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/lanzarote/teguise/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2186",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/lugo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2187",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/lugo/burela/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2188",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/madrid/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2189",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/madrid/madrid/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2190",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/malaga/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2191",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/malaga/estepona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2192",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/malaga/malaga/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2193",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/mallorca/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2194",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/mallorca/cala-millor/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2195",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/mallorca/calvia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2196",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/mallorca/colonia-sant-jordi/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2197",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/murcia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2198",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/murcia/archena/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2199",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/murcia/murcia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2200",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/pais-vasco/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2201",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/pontevedra/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2202",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/pontevedra/pontevedra/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2203",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/pontevedra/vigo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2204",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/region-de-murcia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2205",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/salamanca/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2206",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/salamanca/bermellar/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2207",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/segovia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2208",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/segovia/santo-tome-del-puerto/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2209",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/sevilla/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2210",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/sevilla/sevilla/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2211",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/sevilla/umbrete/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2212",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/tarragona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2213",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/tarragona/salou/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2214",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/tarragona/torroja-del-priorat/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2215",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/tenerife/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2216",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/tenerife/adeje/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2217",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/tenerife/arona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2218",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/tenerife/puerto-de-la-cruz/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2219",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/toledo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2220",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/toledo/toledo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2221",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/valencia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2222",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/valencia/valencia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2223",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/valladolid/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2224",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spa-privado/valladolid/penafiel/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2225",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-bebes/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2226",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-bebes/canarias/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2227",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-bebes/lanzarote/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2228",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-bebes/lanzarote/yaiza/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2229",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-bebes/tenerife/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2230",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-bebes/tenerife/adeje/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2231",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-bebes/tenerife/playa-de-las-americas/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2232",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2233",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/a-coruna/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2234",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/a-coruna/carnota/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2235",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/acores/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2236",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/acores/ponta-delgada/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2237",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/alicante/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2238",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/alicante/benidorm/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2239",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/alicante/calpe/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2240",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/alicante/denia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2241",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/alicante/l-alfas-del-pi/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2242",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/almeria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2243",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/almeria/roquetas-de-mar/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2244",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/andalucia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2245",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/aragon/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2246",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/asturias/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2247",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/asturias/langreo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2248",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/asturias/parres/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2249",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/barcelona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2250",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/barcelona/barcelona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2251",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/barcelona/calella/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2252",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/barcelona/seva/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2253",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/barcelona/sitges/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2254",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/cadiz/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2255",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/cadiz/cadiz/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2256",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/cadiz/chiclana-de-la-frontera/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2257",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/cadiz/rota/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2258",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/cadiz/tarifa/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2259",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/canarias/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2260",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/cantabria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2261",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/cantabria/medio-cudeyo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2262",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/castellon/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2263",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/castellon/peniscola/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2264",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/castilla-la-mancha/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2265",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/castilla-y-leon/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2266",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/cataluna/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2267",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/comunidad-autonoma-de-cantabria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2268",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/comunidad-de-madrid/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2269",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/comunidad-valenciana/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2270",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/cordoba/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2271",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/cordoba/lucena/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2272",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/distrito-de-faro/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2273",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/distrito-de-leiria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2274",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/distrito-de-oporto/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2275",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/distrito-de-setubal/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2276",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/distrito-de-vila-real/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2277",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/faro/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2278",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/faro/albufeira/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2279",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/fuerteventura/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2280",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/fuerteventura/antigua/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2281",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/fuerteventura/pajara/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2282",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/galicia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2283",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/girona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2284",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/girona/caldes-de-malavella/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2285",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/girona/llanars/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2286",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/girona/toses/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2287",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/gran-canaria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2288",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/gran-canaria/mogan/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2289",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/gran-canaria/san-bartolome-de-tirajana/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2290",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/granada/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2291",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/granada/almunecar/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2292",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/granada/granada/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2293",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/guadalajara/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2294",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/guadalajara/brihuega/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2295",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/huelva/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2296",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/huelva/ayamonte/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2297",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/huelva/cartaya/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2298",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/huelva/isla-cristina/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2299",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/huesca/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2300",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/huesca/sallent-de-gallego/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2301",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/islas-baleares/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2302",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/lanzarote/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2303",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/lanzarote/yaiza/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2304",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/leiria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2305",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/leiria/alcobaca/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2306",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/leon/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2307",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/leon/astorga/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2308",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/lleida/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2309",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/lleida/caldes-de-boi/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2310",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/lleida/vallbona-de-les-monges/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2311",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/lugo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2312",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/lugo/guitiriz/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2313",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/lugo/panton/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2314",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/lugo/vilalba/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2315",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/madrid/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2316",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/madrid/madrid/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2317",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/malaga/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2318",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/malaga/marbella/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2319",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/mallorca/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2320",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/mallorca/calvia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2321",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/oporto/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2322",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/oporto/vila-do-conde/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2323",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/pais-vasco/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2324",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/pontevedra/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2325",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/pontevedra/moana/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2326",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/pontevedra/pontevedra/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2327",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/pontevedra/vigo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2328",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/principado-de-asturias/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2329",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/regiao-de-acores/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2330",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/segovia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2331",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/segovia/segovia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2332",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/setubal/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2333",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/setubal/santiago-do-cacem/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2334",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/soria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2335",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/soria/burgo-de-osma-ciudad-de-osma/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2336",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/tarragona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2337",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/tarragona/montbrio-del-camp/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2338",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/tenerife/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2339",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/tenerife/adeje/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2340",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/tenerife/arona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2341",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/tenerife/puerto-de-la-cruz/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2342",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/tenerife/santa-cruz-de-tenerife/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2343",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/toledo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2344",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/toledo/carranque/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2345",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/toledo/toledo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2346",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/valencia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2347",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/valencia/gandia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2348",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/valencia/valencia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2349",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/valladolid/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2350",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/valladolid/olmedo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2351",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/valladolid/penafiel/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2352",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/valladolid/san-bernarndo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2353",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/vila-real/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2354",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/vila-real/boticas/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2355",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/vila-real/ribeira-de-pena/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2356",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/vizcaya/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2357",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-criancas/vizcaya/urduna/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2358",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-gravidas/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2359",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-gravidas/alicante/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2360",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-gravidas/alicante/alicante/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2361",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-gravidas/alicante/benidorm/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2362",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-gravidas/alicante/calpe/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2363",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-gravidas/andalucia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2364",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-gravidas/aragon/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2365",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-gravidas/asturias/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2366",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-gravidas/asturias/langreo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2367",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-gravidas/barcelona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2368",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-gravidas/barcelona/barcelona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2369",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-gravidas/barcelona/sitges/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2370",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-gravidas/cadiz/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2371",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-gravidas/cadiz/chiclana-de-la-frontera/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2372",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-gravidas/canarias/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2373",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-gravidas/cantabria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2374",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-gravidas/cantabria/la-hermida/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2375",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-gravidas/cantabria/torrelavega/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2376",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-gravidas/castilla-la-mancha/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2377",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-gravidas/cataluna/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2378",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-gravidas/comunidad-autonoma-de-cantabria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2379",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-gravidas/comunidad-de-madrid/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2380",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-gravidas/comunidad-valenciana/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2381",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-gravidas/cordoba/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2382",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-gravidas/cordoba/lucena/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2383",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-gravidas/distrito-de-lisboa/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2384",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-gravidas/distrito-de-vila-real/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2385",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-gravidas/galicia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2386",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-gravidas/girona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2387",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-gravidas/girona/llanars/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2388",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-gravidas/gran-canaria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2389",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-gravidas/gran-canaria/maspalomas/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2390",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-gravidas/gran-canaria/mogan/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2391",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-gravidas/gran-canaria/san-bartolome-de-tirajana/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2392",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-gravidas/huelva/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2393",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-gravidas/huelva/ayamonte/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2394",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-gravidas/huesca/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2395",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-gravidas/huesca/sallent-de-gallego/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2396",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-gravidas/islas-baleares/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2397",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-gravidas/lanzarote/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2398",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-gravidas/lanzarote/yaiza/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2399",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-gravidas/lisboa/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2400",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-gravidas/lisboa/lisboa/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2401",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-gravidas/lisboa/torres-vedras/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2402",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-gravidas/madrid/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2403",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-gravidas/madrid/madrid/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2404",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-gravidas/malaga/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2405",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-gravidas/malaga/malaga/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2406",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-gravidas/mallorca/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2407",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-gravidas/mallorca/calvia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2408",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-gravidas/mallorca/colonia-sant-jordi/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2409",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-gravidas/murcia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2410",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-gravidas/murcia/murcia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2411",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-gravidas/pontevedra/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2412",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-gravidas/pontevedra/pontevedra/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2413",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-gravidas/principado-de-asturias/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2414",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-gravidas/region-de-murcia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2415",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-gravidas/tenerife/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2416",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-gravidas/tenerife/adeje/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2417",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-gravidas/tenerife/playa-de-las-americas/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2418",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-gravidas/toledo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2419",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-gravidas/toledo/toledo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2420",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-gravidas/valencia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2421",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-gravidas/valencia/valencia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2422",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-gravidas/vila-real/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2423",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-gravidas/vila-real/boticas/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2424",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/spas-para-gravidas/vila-real/ribeira-de-pena/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2425",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/todas-as-promocoes/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2426",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/todas-as-promocoes/barcelona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2427",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/todas-as-promocoes/barcelona/barcelona/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2428",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/todas-as-promocoes/canarias/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2429",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/todas-as-promocoes/castilla-la-mancha/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2430",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/todas-as-promocoes/cataluna/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2431",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/todas-as-promocoes/gran-canaria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2432",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/todas-as-promocoes/gran-canaria/maspalomas/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2433",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/todas-as-promocoes/gran-canaria/san-agustin-maspalomas/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2434",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/todas-as-promocoes/islas-baleares/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2435",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/todas-as-promocoes/mallorca/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2436",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/todas-as-promocoes/mallorca/colonia-sant-jordi/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2437",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/todas-as-promocoes/tenerife/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2438",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/todas-as-promocoes/tenerife/adeje/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2439",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/todas-as-promocoes/toledo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2440",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/todas-as-promocoes/toledo/toledo/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2441",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/tratamentos-para-ele/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2442",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/tratamentos-para-ele/asturias/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2443",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/tratamentos-para-ele/asturias/parres/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2444",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/tratamentos-para-ele/canarias/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2445",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/tratamentos-para-ele/comunidad-valenciana/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2446",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/tratamentos-para-ele/distrito-de-evora/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2447",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/tratamentos-para-ele/distrito-de-lisboa/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2448",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/tratamentos-para-ele/distrito-de-oporto/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2449",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/tratamentos-para-ele/evora/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2450",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/tratamentos-para-ele/evora/evora/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2451",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/tratamentos-para-ele/gran-canaria/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2452",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/tratamentos-para-ele/gran-canaria/maspalomas/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2453",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/tratamentos-para-ele/gran-canaria/mogan/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2454",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/tratamentos-para-ele/gran-canaria/san-bartolome-de-tirajana/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2455",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/tratamentos-para-ele/lisboa/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2456",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/tratamentos-para-ele/lisboa/ericeira/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2457",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/tratamentos-para-ele/lisboa/lisboa/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2458",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/tratamentos-para-ele/oporto/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2459",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/tratamentos-para-ele/oporto/vila-do-conde/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2460",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/tratamentos-para-ele/principado-de-asturias/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2461",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/tratamentos-para-ele/tenerife/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2462",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/tratamentos-para-ele/tenerife/adeje/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2463",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/tratamentos-para-ele/valencia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2464",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/tratamentos-para-ele/valencia/valencia/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2465",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/visita-e-estadia-de-natal/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2466",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/visita-e-estadia-de-natal/castilla-y-leon/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2467",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/visita-e-estadia-de-natal/salamanca/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  },
  {
    name: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.name ?? "Promotion landing: 2468",
    path: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.path ?? "/pt/promocoes/visita-e-estadia-de-natal/salamanca/bermellar/",
    props: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.props ?? false,
    meta: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta || {},
    alias: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.alias || [],
    redirect: Promotion_46pagejl6gNZiNM1S6ovQ5IXFnE3SDAJt0zYinnY2djthO5JcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/core/pages/promotion/Promotion.page.vue")
  }
]