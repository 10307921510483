import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_hxpx9TAykM4St_ybsPIAJmhOKQmae5Yd749dL71wvC8 from "/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import click_outside_I4ttNMqHV6xHGjmJUbCn_KeHhF4cThlycOt7HfJGaS4 from "/usr/src/app/content/n3-promos-6d36203d-d8c2-4878-9478-e0b04ace9092/b2c-build/plugins/click-outside.ts";
export default [
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  _0_siteConfig_hxpx9TAykM4St_ybsPIAJmhOKQmae5Yd749dL71wvC8,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  click_outside_I4ttNMqHV6xHGjmJUbCn_KeHhF4cThlycOt7HfJGaS4
]